import { Box, Checkbox, Divider, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { AddUpdateAndDeleteCuratedList } from '../action/TripPlannerAction';
import { commonFetchData } from '../../../commons/utility';
import LoadingOverlay from '../../../commons/components/Loader/LoadingOverlay';

const CuratedListForm = ({triggerSaveRef,idsRef,data,selectALlRef}) => {

const [bodyData, setbodyData] = useState(data);
const [formError, setFormError] = useState({
  name:"",
  description:"",
});
const [loading, setLoading] = useState(false);

const dispatch = useDispatch()
const selectedHotel = useSelector(
    (state) => state.filter.selectedHotel || {}
  );
const location = useLocation()
const history = useHistory();
const params = new URLSearchParams(location.search)
const id = params.get("id")

useEffect(()=>{ 
if(data)
  setbodyData(data)
},[data])

  const isValid = () => {
    let valid = true;
    let requiredFields = ["name", "description"];
  
    requiredFields.forEach((key) => {
      if (!bodyData[key] || bodyData[key].trim().length === 0) {
        valid = false;
        setFormError((prev) => ({ ...prev, [key]: "This field is required" }));
      }
    });
  
    return valid;
  };


  const handleChange = (e)=>{
    e?.stopPropagation()
    let { name, value,checked } = e.target;
    console.log(name,value,checked);

      if (formError[name] && `${value}`.trim())
        setFormError((prev) => ({ ...prev, [name]: "" }));
        setbodyData((prev)=>({
        ...prev,
        [name]:checked!== undefined? checked : value,
        
    }))

}

    const handleSave = () => {
          console.log(bodyData);
          
          if (!isValid()) return;
          setLoading(true);
          let data = bodyData
          if (selectALlRef?.current)
            data.event_or_place_list = true
            else
          data.event_or_place_list = Object.keys(idsRef?.current) || []
               if(id){
                      dispatch(AddUpdateAndDeleteCuratedList({...data,id},true, setLoading,"patch"))
                    }
                    else{
                      dispatch(AddUpdateAndDeleteCuratedList(data,false, setLoading,"post")).then((data) => {
                          console.log("Response Data:", data);
                          params.set("id",data.id)
                          history.replace({ search: params.toString() });
                        console.log(bodyData);
                        }) 
                    }
      }

          useEffect(() => {
      
              if (triggerSaveRef)
                triggerSaveRef.current = () => handleSave();
             }, [handleSave]);


  return (
    <Stack spacing={3} maxWidth={"50%"}>
      <LoadingOverlay isLoading={loading} message="Saving data..." />
            <Box>
              <Typography variant="h6" color="textPrimary">
                Information
              </Typography>
              <Divider sx={{ my: 1 }} />
            </Box>
    
            <Stack spacing={1}>
              <Typography variant="body2" color="textPrimary">
                Name
              </Typography>
              <TextField
                fullWidth
                name="name"
                // variant='outlined'
                size="small"
                value={bodyData.name}
                onChange={handleChange}
                error={formError["name"]}
                helperText={formError["name"]}
                disabled={loading}
              />
              <Typography variant="caption" color="textSecondary">
                Maximum of 240 characters
              </Typography>
            </Stack>
    
            <Stack spacing={1}>
              <Typography variant="body2" color="textPrimary">
                Description
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                 size="small"
                multiline
                rows={4}
                placeholder="Write a description"
                name="description"
                value={bodyData.description}
                onChange={handleChange}
                error={formError["description"]}
                helperText={formError["description"]}
                disabled={loading}
              />
              <Typography variant="caption" color="textSecondary">
                Maximum of 240 characters
              </Typography>
            </Stack>
    
            <Stack direction="row" spacing={1} alignItems="center">
              <Checkbox
                     name="auto_populate"
                     checked={bodyData.auto_populate}
                     onChange={handleChange}
                     error={formError["auto_populate"]}
                     helperText={formError["auto_populate"]}
                     disabled={loading}
                    //  defaultChecked={bodyData.auto_populate}
              />
              <Typography variant="body2" color="textPrimary">
                Auto populate
              </Typography>
            </Stack>
    
            <Stack direction="row" spacing={1} alignItems="center">
              <Checkbox
                     name="personalize"
                     checked={bodyData.personalize}
                     onChange={handleChange}
                     error={formError["personalize"]}
                     helperText={formError["personalize"]}
                     disabled={loading}
                    //  defaultChecked={bodyData.personalize}
              />
              <Typography variant="body2" color="textPrimary">
                Personalize
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Checkbox
                     name="enabled"
                     checked={bodyData.enabled || false}
                     onChange={handleChange}
                     error={formError["enabled"]}
                     helperText={formError["enabled"]}
                     disabled={loading}
                    //  defaultChecked={bodyData.enabled}
              />
              <Typography variant="body2" color="textPrimary">
                Enable
              </Typography>
            </Stack>
          </Stack>
  )
}

export default CuratedListForm

import * as actionTypes from "./actions/actionTypes";

const initialState = {
  loading: false,
  action_loading: false,
  analytics_loading: true,
  guest_requests: { count: 0, page: 0, data: [],next:null },
  error: "",
  error_in_update: "",
  staff: [],
  form_options: {},
  analytics: {},
};

const update_guest_request_in_list = (lst, new_req) => {
  var _old_idx = lst.findIndex((_m) => _m.id == new_req.id);

  if (_old_idx == -1) return [...lst];

  var _filtered_requests = lst.filter((_m) => _m.id != new_req.id);
  _filtered_requests.splice(_old_idx, 0, new_req);
  return _filtered_requests;
};

const guestRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_GUEST_REQUESTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_GUEST_REQUESTS_SUCCESS:
      return {
        ...state,
        guest_requests: action.payload,
        error: "",
        loading: false,
      };
    case actionTypes.FETCH_GUEST_REQUESTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case actionTypes.UPDATE_GUEST_REQUEST_SUCCESS:
      const filtered = update_guest_request_in_list(
        state.guest_requests.data,
        action.payload
      );
      return {
        ...state,
        guest_requests: { ...state.guest_requests, data: filtered },
        error: "",
      };

    case actionTypes.UPDATE_GUEST_REQUEST_ERROR:
      return {
        ...state,
        error_in_update: action.payload,
      };

    case actionTypes.FETCH_STAFF_LIST_SUCCESS:
      return {
        ...state,
        staff: action.payload,
        error_in_update: "",
      };

    // case actionTypes.APPEND_STAFF_LIST_SUCCESS:
    //   return {
    //     ...state,
    //     staff: {
    //       ...(state.staff || {}),
    //       [action.payload.id]: [
    //         ...(state.staff[action.payload.id] || []),
    //         ...action.payload.data,
    //       ],
    //     },
    //     error_in_update: "",
    //   };

    case actionTypes.COMMON_TASK_REQUEST:
      return {
        ...state,
        action_loading: true,
      };

    case actionTypes.COMMON_TASK_ERROR:
      return {
        ...state,
        action_loading: false,
        error: action.payload,
      };

    case actionTypes.FETCH_FORM_DATA_SUCCESS:
      return {
        ...state,
        form_options: action.payload,
        error: "",
      };

    case actionTypes.ADD_NEW_TASK_SUCCESS:
      if (
        state.guest_requests.data.length >=
        (state.guest_requests.page + 1) * 10
      )
        state.guest_requests.data.pop();
      return {
        ...state,
        action_loading: false,
        error: "",
        guest_requests: {
          ...state.guest_requests,
          count: state.guest_requests.count + 1,
          data: [action.payload, ...state.guest_requests.data],
        },
      };

    // case actionTypes.FETCH_ANALYTICS_REQUEST:
    //   return {
    //     ...state,
    //     analytics_loading: true,
    //   };

    case actionTypes.FETCH_ANALYTICS_SUCCESS:
      return {
        ...state,
        analytics_loading: false,
        analytics: action.payload,
        error: "",
      };

    case actionTypes.FETCH_ANALYTICS_ERROR:
      return {
        ...state,
        analytics_loading: false,
        error: action.payload,
      };

    case actionTypes.SET_TO_INITIAL:
      return initialState;

    default:
      return state;
  }
};

export default guestRequestsReducer;

import React from "react";
import { Box, Typography, Paper } from "@mui/material";

const Preview = ({ htmlContent }) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Preview
      </Typography>
      <Paper elevation={3} sx={{ padding: 2, marginTop: 2 }}>
        {htmlContent ? (
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#f9f9f9",
            }}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
          />
        ) : (
          <Typography variant="body1" color="textSecondary">
            Nothing to preview.
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default Preview;

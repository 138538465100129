import React from 'react';
import { Box, Card, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import success from "../assets/mirage-message-sent.png";

const SurveySuccess = ({ surveyAlreadySubmitted, onNext ,data={}}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        backgroundColor: '#F9FAFA',
        minHeight: '90vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2
      }}
    >
      <Card
        sx={{
          width: isMobile ? '100%' : '440px',
          minHeight: '300px',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent:"center",
          gap: 2,
          boxShadow: 3,
          borderRadius: 3
        }}
      >
{
  data?.survey?.on_submit_photo &&         <img
  src={data?.survey?.on_submit_photo}
  alt="Success"
  style={{
    width: isMobile ? '150px' : '200px',
    height: 'auto',
    marginBottom: '16px'
  }}
/>
}
        {/* <Typography
          variant="h5"
          align="center"
          sx={{ fontSize: isMobile ? '1.25rem' : '1.5rem', fontWeight: 'bold' }}
        >
          {surveyAlreadySubmitted 
            ? "This survey has already been submitted" 
            : "Survey submitted successfully"}
        </Typography> */}
        <Typography
          variant="body1"
          align="center"
          sx={{ fontSize: isMobile ? '0.875rem' : '1rem', color: '#555' }}
        >
        {
          data?.survey?.on_submit_msg || "Thank you for submitting the survey."
        }
        </Typography>
      </Card>
    </Box>
  );
};

export default SurveySuccess;

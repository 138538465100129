import React, { useState } from "react";
import {
  Grid,
  Box,
  Paper,
  Typography,
  IconButton,
  Link,
} from "@mui/material";
import { setGlobalMessage } from "../../actions/dashboardActions";
import { useDispatch } from "react-redux";
import ImageIndex from "./ImageIndex";
import DeleteSvg from "./assets/DeleteSvg";
import { delete_Place_Events_image } from "../../commons/components/Settings/actions/settingsActions";

const ImageUpload = ({images, setImages,id=null,inputHandler}) => {
  const dispatch = useDispatch();

  const handleFiles = (files) => {
    const allowedFormats = new Set([
      "image/jpeg",
      "image/jpg",
      "image/bmp",
      "image/gif",
      "image/heic",
      "image/png",
    ]);
  
    const validFiles = [];
    const skippedFiles = [];
  
    Array.from(files).forEach((file) => {
      if (allowedFormats.has(file.type)) {
        validFiles.push(file);
      } else {
        skippedFiles.push(file);
      }
    });
  
    const messages = [];
    if (validFiles.length === 0) {
      messages.push("No files are supported. Please upload files in allowed formats: JPEG, JPG, BMP, GIF, HEIC, PNG.");
    } else if (skippedFiles.length > 0) {
      messages.push("Some files were skipped as they are not in allowed formats: JPEG, JPG, BMP, GIF, HEIC, PNG.");
    }
  
    if (messages.length > 0) {
      dispatch(setGlobalMessage({ mess: messages.join(" "), type: "warning" }));
    }
  
    if (validFiles.length === 0) {
      return; 
    }
  
    const fileUrls = validFiles.map((file) => ({
      image: URL.createObjectURL(file),
      file, 
    }));
  
    let combinedImages = [...images, ...fileUrls];
    setImages((prev) => combinedImages);
  
    if(id)
    inputHandler(combinedImages,id);
  };


  

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      handleFiles(event.dataTransfer.files);
      event.dataTransfer.clearData();
    }
  };

  const handleDelete = (index) => {
   let d =images?.[index]
    setImages((prev) => prev.filter((_, i) => i !== index));

    if(d && d?.id){
      dispatch(delete_Place_Events_image(d.id,null,d.place_or_event))
    }
    
  };

  const handleBrowse = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      handleFiles(event.target.files);
    }
  };

  return (
    <Box sx={{ width: "480px" }}>
      <Typography variant="h6" fontWeight="bold">
        Images
      </Typography>
      <Grid container spacing={2}>
        {images.map((image, index) => (
          <Grid item xs={4} key={index}>
            <Paper
              sx={{
                position: "relative",
                p: 0.5,
                borderRadius: 2,
                overflow: "hidden",
                height: 154,
                width: 154,
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${image?.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "100%",
                  borderRadius: 2,
                  height: "100%",
                }}
              />
        <ImageIndex index={index + 1} addCss={{position: "absolute", bottom: 12, left: 12}} />
              <IconButton
                sx={{
                  position: "absolute",
                  bottom: 12,
                  right: 12,
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  height: 24,
                  width: 24,
                  borderRadius: "4px",
                  padding: 0,
                }}
                onClick={() => handleDelete(index)}
              >
               <DeleteSvg/>
              </IconButton>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Box
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
        sx={{
          mt: 2,
          p: 2,
          border: "1.5px dashed #00000040",
          borderRadius: 1,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "14px",
          cursor: "pointer",
        //   backgroundColor: "#f9f9f9",
        }}
      >
        <input
          type="file"
          multiple
          accept="image/*"
          style={{ display: "none" }}
          id="fileInput"
          onChange={handleBrowse}
        />
        <label htmlFor="fileInput">
          {/* <ImageIcon sx={{ fontSize: 40 }} /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<g id="lucide/image-up">
<path id="Vector" d="M10.3 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15L17.9 11.9C17.5237 11.5312 17.017 11.3258 16.4901 11.3284C15.9632 11.331 15.4586 11.5415 15.086 11.914L6 21M14 19.5L17 16.5M17 16.5L20 19.5M17 16.5V22M11 9C11 10.1046 10.1046 11 9 11C7.89543 11 7 10.1046 7 9C7 7.89543 7.89543 7 9 7C10.1046 7 11 7.89543 11 9Z" stroke="#4A575A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ cursor: "pointer",maxWidth: "230px" }}
          >
            Drop your images here or{" "}
            <Link component="span" underline="hover">
              browse
            </Link>
            . JPEG, JPG, BMP, GIF, HEIC
          </Typography>
        </label>
      </Box>
    </Box>
  );
};

export default ImageUpload;

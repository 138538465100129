import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown, faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
import ProgressSlider from './ProgressSlider';
import SectionRenderer from './SectionRenderer';
import {saveSectionResponses} from '../../../../../actions/surveyActions';
import {add_auth_header, get_query_param} from '../../../../../components/Utility/Utility';

import css from '../UserSurvey.module.css';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { commonFetchData } from '../../../../../commons/utility';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { commonColors } from '../../../../../components/Utility/Constants';

const SurveyRenderer = props => {

    const [response_sections, setResponseSections] = useState([]);
    const [surveyToken, setSurveyToken] = useState(null);
    const [sections, setSections] = useState([]);
    const [sectionCount, setSectionCount] = useState(null);
    const [currSection, setCurrSection] = useState(null);
    const [currSectionIdx, setCurrSectionIdx] = useState(null);
    const [isPrev, setIsPrev] = useState(false);
    const [isNext, setIsNext] = useState(false);
    const [responses, setResponses] = useState({})
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const preview = queryParams.get("preview") || null;
    const camp_code = queryParams.get("camp_code") || null

    
    const getSectionResponses = (sec_id) => {


        const _resp = response_sections.find( sr => {return sr.section == sec_id});

        console.log("survey answer 1", _resp)

        return _resp
    }

    const updateResponsesForSection = (section_responses) => {
        let _resps = getSectionResponses(section_responses.section);

        if (_resps) Object.assign(_resps, section_responses);
        else response_sections.push(section_responses);
    }

    useEffect(() => {
        
        setSurveyToken(props.surveyToken);
        setSections(props.survey.sections);
        setSectionCount(props.survey.sections_count);
        if (props.surveyResponseObj) {setResponseSections(props.surveyResponseObj.response_sections || []);}
        if(props.survey.sections_count > 0){
            setCurrSectionIdx(0);
            props.setCurrSection(0);
        }
    }, [props.surveyToken, props.survey])

    useEffect(() => {
        console.log(sectionCount,currSectionIdx);
        
        if(currSectionIdx >= 0){
            setCurrSection(sections[currSectionIdx]);
        }
            
        if (currSectionIdx > 0)
            setIsPrev(true)
        else
            setIsPrev(false)
        
        if (currSectionIdx < sectionCount-1)
            setIsNext(true)
        else
            setIsNext(false)

    }, [currSectionIdx])
    
    const sectionHandler = (e,type) => {
        switch(type){
            
            case 'next':
                if (currSectionIdx < sectionCount-1)
                    setCurrSectionIdx( prev => prev+1);
                    props.setCurrSection(currSectionIdx + 1);
                    // setResponseSections([])
                break;

            case 'back':
                if (currSectionIdx > 0)
                    setCurrSectionIdx( prev => prev-1);
                    props.setCurrSection(currSectionIdx - 1);
                break; 
            case 'done':
                console.log("response_sections", currSectionIdx, response_sections,sectionCount);
                if (currSectionIdx <= sectionCount-1)
                    
                    // setCurrSectionIdx( prev => prev+1);
                    submitSectionResponse()
                break;
            default:
                console.log('Invalid type');
        }
    }

    const submitSectionResponse = () => {
        props.saveSectionResponses(props.add_auth_header({}), surveyToken, response_sections[currSectionIdx], (status,data={}) => {
            if (status) {
                sectionHandler(null, 'next');
                props.showSectionSuccess(true);
                props.setCurrSection(currSectionIdx + 1);
                props.setAPIData((prevData) => {
                    const updatedData = { ...prevData };
            
                    if (updatedData?.response_sections && Array.isArray(updatedData?.response_sections)) {
                        // Check if an entry with the same `section` or `survey_response` already exists
                        const existingIndex = updatedData.response_sections.findIndex(
                            (item) => item.section === data.section || item.survey_response === data.survey_response
                        );
            
                        if (existingIndex > -1) {
                            // Update the existing object
                            updatedData.response_sections[existingIndex] = data;
                        } else {
                            // Push the new object
                            updatedData.response_sections.push(data);
                        }
                    } else {
                        // Initialize response_sections array with the new data
                        updatedData.response_sections = [data];
                    }
            
                    // Update the `completed` status if not moving to the next section
                    if (!isNext) {
                        updatedData.completed = true;
                    }
                    
                    console.log("end",updatedData);
                    setResponseSections(updatedData.response_sections)
                    return updatedData;
                });
            }
            
                // commonFetchData(props.get_url(),{req:"get"}).then(data=>props.setAPIData(data))
            // setResponseSections([])
            // if (updateResponsesForSection) updateResponsesForSection(responses);
        },camp_code);
    }

    return(
        <React.Fragment>{console.log("currSection",currSection, currSectionIdx)}
            <div className = {css.SurveyQuestionsContainer}>
                {/* <ProgressSlider survey = {props.survey} currentQues = {0} currentSec = {0}/> */}
                <div className = {css.QuestionsContainer}>

                    {currSection && <SectionRenderer 
                                        index={currSectionIdx+1}
                                        section={currSection}
                                        surveyToken={surveyToken}
                                        sectionHandler={sectionHandler}
                                        setResponses={updateResponsesForSection}
                                        responses={currSection ? getSectionResponses(currSection.id) : null}
                                        updateResponsesForSectionInParent={updateResponsesForSection}
                                        />}

                {/* <SurveyRenderer apiData = {survey} currentQues = {currQuestion} saveResponse = {userResponseHandler}
                setCurrentQues = {setCurrQuestion} currentSec = {currSection} setCurrentSec = {sectionHandler} userResponse = {userResponse}/> */}
                </div>
            </div>


            {/* <h4 className = {css.section_head}><span>{props.currentSec + 1}.</span>{props.apiData.sections[props.currentSec].name}</h4>
            <p className = {css.Question}>{
            props.apiData.sections[props.currentSec].questions.length === 0 ?
            'Section Empty':props.apiData.sections[props.currentSec].questions[props.currentQues].question_txt+' ?'}</p>
            
            <InputTypes type ={props.apiData.sections[props.currentSec].questions.length === 0 ? '' : 
            props.apiData.sections[props.currentSec].questions[props.currentQues].display} userResponse = {props.userResponse}
            option = {props.apiData.sections[props.currentSec].questions.length === 0 ? [] :
            props.apiData.sections[props.currentSec].questions[props.currentQues].options } setResponse = {setResponse}
            currentSec = {props.currentSec} currentQues = {props.currentQues}/> */}

            <div className = {css.BackNextContainer} style={{justifyContent: `${currSectionIdx == 0 ? "flex-end" : "flex-end"}`,gap:"10px"}}>

                {currSectionIdx !== 0 &&  preview && <Button onClick = {(e) => sectionHandler(e,'back')} sx={{width: '142px', height: '42px'}}  style={{background:commonColors.soft_gray}} variant='contained'>Back</Button>}
               { !preview && <Button disabled={false} onClick = {(e) => sectionHandler(e,'done')} sx={{width: '142px', height: '42px',alignSelf:"end"}} style={{background:commonColors.soft_gray}} variant='contained'>Submit</Button>}
                {isNext && preview && <Button onClick = {(e) => sectionHandler(e,'next')} sx={{width: '142px', height: '42px'}}  style={{background:commonColors.soft_gray}} variant='contained'>Next</Button>}

                {/* {isPrev && <p onClick = {(e) => sectionHandler(e,'back')}><FontAwesomeIcon icon = {faLongArrowAltUp}/> back</p>}
                {isNext && <p onClick = {(e) => sectionHandler(e,'next')}>next <FontAwesomeIcon icon = {faLongArrowAltDown}/></p>} */}
            </div>


            
        </React.Fragment>
    );
}

const mapDispatchToProps = dispatch => {
    return{
        add_auth_header: bindActionCreators(add_auth_header, dispatch),
        saveSectionResponses: bindActionCreators(saveSectionResponses, dispatch)
        };
    };

export default connect(null,mapDispatchToProps)(SurveyRenderer);
export const COLOR_POSITIVE="#d9f7be"
export const COLOR_NEGATIVE="#FF616180"
export const COLOR_MIXED="#ffffb8"
export const COLOR_NEUTRAL="#E6F7FF"


export const commonColors = {
    soft_gray:"#E4E6E7"
}

export const colorWithType = {
    new:{
        bg:"#D6F5DB",
        tx:"#008000"
      }
    ,
    active:{
      bg:"#0074E8",
      tx:"#E5F2FF"
    },
    inactive:{
      bg:"#E4E6E7",
      tx:"#303336"
    },
    failed:{
      bg:"#F8E6E5",
      tx:"#E24536"
    }
  }
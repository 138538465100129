import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CommonSingleRow from '../../../commons/components/Settings/components/CommonSingleRow';
import CommonTableUI from '../../../commons/components/Settings/components/CommonTableUI';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchSurveyResponse, updateSurveyResponseDataByKey } from '../action/SurveyAction';
import { Check, Close } from '@material-ui/icons';
import { formatDate } from '../../../commons/utility';

const columns = [
  { name: "Survey Name" },
  { name: "Survey Language" },
  {name:"Guest Name"},
  {name:"Guest Email"},
  {name : "Completed"},
  { name: "Submitted On" },
  { name: "Action" },
];

const SurveyResponseTable = () => {

  const dataSelector = state => state?.surveyStore?.SurveyResponse?.results || [];
  const loadingSelector = state => state?.surveyStore?.SurveyResponse?.loading;
  const errorSelector = state => state?.surveyStore?.SurveyResponse?.error;
  const count = useSelector((state)=>state?.surveyStore?.SurveyResponse?.count) || 0;
  const minRow = 10;
  const page = Math.ceil(count / minRow);

  const dispatch = useDispatch()
  const navigate = useHistory()


    const fetchData = (page=0)=>{
      dispatch(updateSurveyResponseDataByKey("loading",true))
      dispatch(fetchSurveyResponse(page,{attempted : true}))
    }

    const RowComponent = ({row}) => {
    
      let payload = {
          "survey_name" :row?.survey?.name,
          "language" : row?.language? row?.language?.label : "",
          "guest_name": row?.guest_name ||  "",
          "guest_email": row?.guest_email ||  "",
          "completed":row?.completed ? <Check/>: <Close/>,
          "submit_on":row?.submitted_on? formatDate(row?.submitted_on) : ""
      }
      return (
        <>
     
              <CommonSingleRow
                key={`${row.id}`}
                payload={payload}
                showMenu={true}
                id={row.id}
                firstRowCap={false}
                menuItems={menuItems}
                fullData={row}
               commonCss= {{ whiteSpace: 'wrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '270px', textTransform:"none"}}
              />
        </>
      );
    };

  const menuItems = [
    // { label: 'View Response', onClick: (data)=>navigate.push(`/user-survey?surveyId=${data?.survey?.id}&preview=1&token=${data.token}`) },
    {
      label: 'View Response',
      onClick: (data) => {
        const url = `/user-survey?surveyId=${data?.survey?.id}&preview=1&token=${data.token}&viewResp=1`;
        window.open(url, '_blank');
      }
    }
];
  return (
    <CommonTableUI
    columns={columns}
    fetchData={fetchData}
    dataSelector={dataSelector}
    loadingSelector={loadingSelector}
    errorSelector={errorSelector}
    isEditPermission={true}
    RowComponent = {RowComponent}
    minRow={minRow}
    count={count}
    page={page}
    maxHeight={1096}
    heading={true}
    title={"Survey Response"}
  />
  )
}

export default SurveyResponseTable

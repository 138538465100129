import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Paper } from "@mui/material";

const HtmlCodeEditor = ({ initialContent, onContentChange }) => {
  const [htmlContent, setHtmlContent] = useState(initialContent || "");

  useEffect(() => {
    setHtmlContent(initialContent || "");
  }, [initialContent]);

  const handleContentChange = (e) => {
    setHtmlContent(e.target.value);
    onContentChange(e.target.value);
  };

  return (
    <Box >
      <Paper elevation={3} sx={{ padding: 2 }}>
        <TextField
          multiline
          fullWidth
          minRows={15}
          maxRows={15}
          variant="outlined"
          value={htmlContent}
          onChange={handleContentChange}
          placeholder="Write your HTML code here..."
          sx={{ fontFamily: "monospace" }}
        />
      </Paper>
    </Box>
  );
};

export default HtmlCodeEditor;

import { setGlobalMessage } from "../../../actions/dashboardActions";
import { SET_TRIP_PLANNER_DATA } from "../../../commons/components/Settings/actions/settingActionTypes";
import { fetchReqForDataSuccessCommon } from "../../../commons/components/Settings/actions/settingsActions";
import { addFilterKey, addSortParams } from "../../../commons/utility";
import axiosClient from "../../../httpClient";

export const fetchCuratedList = (page=0,setLoading=false,isSetLoadingIsState=false,query=null,parameters={}) => {
    return (dispatch, getState) => {
      let {
        filter: { selectedHotel,selectedFilters },
        settings:{PlaceAndEvent},
        auth: { token }
      } = getState(); 
      const { } = parameters
     selectedFilters = selectedFilters[parameters["filterkey"]] || []
      if(!token)
        return
  
      const params = new URLSearchParams({
        hotel: selectedHotel.hotel_code,
        ...(page===null?{}:{page: page + 1}),
      });
      
      if (query) {
        params.append("q", query);
      }
      
      let url = `/planner/places-events/groups/?${params.toString()}`;
  
      if(selectedFilters.length>0)
        url = addFilterKey(selectedFilters,url,"&")
  
      if(parameters.orderBy && Object.keys(parameters.orderBy).length>0){
        url = addSortParams(parameters.orderBy,url,"&")
      }
  
  
      console.log(url,query)
      axiosClient
        .get(
          url
        )
        .then((response) => {
          const data = {
            data: [
              ...(page != 0 ? PlaceAndEvent[parameters?.entityType|| "Curated_List"]?.data : []),
              ...response.data,
            ],
            page,
            count: response.data?.length,
            previous:null,
            next:null
          };
          dispatch(fetchReqForDataSuccessCommon({
            [parameters.entityType]:data
          },SET_TRIP_PLANNER_DATA));       
           if(setLoading){
            if(isSetLoadingIsState)
            setLoading(false)
            else
            dispatch(setLoading(false,parameters.entityType))
          }
        })
        .catch((error) => {
          if(setLoading){
            if(isSetLoadingIsState)
            setLoading(false)
            else{
              dispatch(setLoading(false,parameters.entityType))
            }
         
          }
          const errorMsg = error.message;
          dispatch(setGlobalMessage({
            mess:errorMsg || "Something went wrong",
            type:'error'
           }))
        }).finally(()=>{ 
        });
    };
  };

  export const fetchAllPlaceAndEventData = (page=0,setLoading=false,isSetLoadingIsState=false,query=null,closeModel,parameters={}) => {
    return (dispatch, getState) => {
      let {
        filter: { selectedHotel,selectedCurrDate,selectedFilters },
        settings:{PlaceAndEvent},
        auth: { token }
      } = getState(); 
      const {  setSearchLoading = null } = parameters
     selectedFilters = selectedFilters[parameters["filterkey"]] || []
      if(!token)
        return
  
      const params = new URLSearchParams({
        hotel: selectedHotel.hotel_code,
        ...(page===null?{}:{page: page + 1}),
      });
      
      if (query !== null) {
        params.append("q", query);
      }
      
      let url = `/data-aggregator/places-events/?${params.toString()}`;
  
      if(selectedFilters.length>0)
        url = addFilterKey(selectedFilters,url,"&")
  
      if(parameters.orderBy && Object.keys(parameters.orderBy).length>0){
        url = addSortParams(parameters.orderBy,url,"&")
      }
  
  
      console.log(url,query)
      axiosClient
        .get(
          url
        )
        .then((response) => {
          const data = {
            data: [
              ...(page != 0 ? PlaceAndEvent[parameters?.entityType|| "Curated_List_Options"]?.data : []),
              ...response.data.results,
            ],
            page,
            count: response.data.count,
            previous:response.data.previous,
            next:response.data.next
          };
          dispatch(fetchReqForDataSuccessCommon({
            [parameters.entityType]:data
          },SET_TRIP_PLANNER_DATA));        if(setLoading){
            if(isSetLoadingIsState)
            setLoading(false)
            else
            dispatch(setLoading(false,parameters.entityType))
          }
        })
        .catch((error) => {
          if(setLoading){
            if(isSetLoadingIsState)
            setLoading(false)
            else{
              dispatch(setLoading(false,parameters.entityType))
            }
         
          }
          const errorMsg = error.message;
          dispatch(setGlobalMessage({
            mess:errorMsg || "Something went wrong",
            type:'error'
           }))
        }).finally(()=>{
          if(closeModel)
            closeModel()
          if(setSearchLoading)
            setSearchLoading(false)
        });
    };
  };

  export const AddUpdateAndDeleteCuratedList = (
    body,
    isEdit = false,
    setLoading,
    reqType = "post"
  ) => {
    return (dispatch, getState) => {
      const {
        filter: { selectedHotel },
      } = getState();
  
      const isDelete = reqType === "delete";
      const url = `/planner/places-events/groups/${isEdit || isDelete ? `${body.id}/` : ''}?hotel=${selectedHotel.hotel_code}`;
  
      console.log(reqType, isEdit, url, body);
  
     return axiosClient[reqType](url, body)
        .then((res) => {
          dispatch(
            setGlobalMessage({
              mess: isDelete ? "Department is successfully deleted." : "Details are successfully saved.",
              type: "success",
            })
          );
  
          // dispatch(fetchDepartment());
          return res.data
        })
        .catch((error) => {
          console.error("Error occurred:", error);
          dispatch(
            setGlobalMessage({
              mess: error.message || "An error occurred.",
              type: "error",
            })
          );
        })
        .finally(() => {
        if(setLoading)
          setLoading(false);
        });
    };
  };


  export const AddUpdateEventVenue = (body,isEdit=false,setLoading,parameters={}) => {
    return (dispatch, getState) => {
      const {
        filter: { selectedHotel },
        settings:{PlaceAndEvent}
      } = getState();
      const url = `/data-aggregator/places-events/${body?.id ? `${body.id}/` : ''}?hotel=${selectedHotel.hotel_code}`;
      const method = body?.id ? 'patch' : 'post';
  
       axiosClient[method](url, body)
        .then((res) => {
          let data = []
          if(isEdit){
            data =  PlaceAndEvent[res?.data?.type]?.data?.map((e)=>{
              if(e.id === res?.data.id)
                return res.data
              else
              return e
            })
          }
          else{
            data = [res.data,...PlaceAndEvent[res?.data?.type]?.data]
          }
            let _d = {
              [res.data.type]: {
                ...PlaceAndEvent[res.data.type],
                data,
                ...(!isEdit?{count:PlaceAndEvent[res.data.type]?.count + 1}:{})
              }
            }
            dispatch(fetchReqForDataSuccessCommon(_d,SET_TRIP_PLANNER_DATA));
            dispatch(setGlobalMessage({
              mess: "Detail successfully saved..",
              type: 'success'
            }))
        })
        .catch((errors) => {
          let errorMsg;
    if (typeof errors === 'object' && errors !== null) {
      errorMsg = Object.keys(errors).map((key) => {
        const value = errors[key];
  
        if (Array.isArray(value)) {
          return `${key}: ${value.join(', ')}`;
        } else if (typeof value === 'string') {
          return `${key}: ${value}`;
        } else {
          return `${key}: ${JSON.stringify(value)}`;
        }
      }).join('\n');
    } else {
      errorMsg = "Something went wrong" || errorMsg;
    }
  
          dispatch(setGlobalMessage({
            mess: errorMsg,
            type: 'error'
          }));
        })
        .finally(()=>{
          if(setLoading)
            setLoading(false)
        })
  
    };
  };
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { 
  IconButton, 
  TextField, 
  Box, 
  Select, 
  MenuItem,
  styled,
  Typography,
  Grid
} from '@mui/material';
import {
  Delete,
  Menu,
  Edit,
  Cancel,
  Save,
  AddCircle,
  CancelRounded,
} from '@mui/icons-material';

// Assuming these components are already updated to MUI v5
import QuestionTypes from './QuestionTypes/questionTypes';
import SliderComp from './QuestionTypes/Slider';
import MultiOptions from './QuestionTypes/multiOptions';
import TextBox from './QuestionTypes/TextBox';
import Scale from './QuestionTypes/Scale';
import DialogBox from '../../../../../commons/components/PopUp/DialogBox';

import { add_auth_header, deepCopy } from "../../../../../components/Utility/Utility";

import { createNewQuestion, updateQuestion, deleteQuestion, getQuestion, createNewDependentQuestion } from '../../../../../actions/surveyActions';
import { LoadingButton } from '@mui/lab';
import { commonColors } from '../../../../../components/Utility/Constants';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const initialOptionState = {
  show:false,
  value: {"left": "", "right": ""},
  error: {"left": "", "right": ""}
}


const QuestionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  marginBottom: theme.spacing(2)
}));

const ActionContainer = styled(Box)({
  display: 'flex',
  gap: '8px',
  alignItems: 'center'
});

const InputTypeContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  padding: theme.spacing(0, 3)
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  minWidth: 120,
  '& .MuiSelect-select': {
    padding: theme.spacing(1)
  }
  
}));

const QuestionComponent = ({
  surveyId,
  sectionId,
  question: initialQuestion,
  index,
  debug,
  cancelAddingNewQuestion,
  updateMainQuestionData,
  handleQuestionDeleteFromSection,
  showOptionLabel
}) => {
  const dispatch = useDispatch();
  
  const [question, setQuestion] = useState({});
  const [originalQuestion, setOriginalQuestion] = useState({});
  const [editable, setEditable] = useState(false);
  const [showDelPopup, setShowDelPopup] = useState(false);
  const [isNewQuestion, setIsNewQuestion] = useState(false);
  const [optionLabel,setOptionLabel] = useState(initialOptionState)

  useEffect(() => {
    setQuestion(deepCopy(initialQuestion));
    setOriginalQuestion(initialQuestion);
    
    
    if (initialQuestion && !initialQuestion.id) {
      setEditable(true);
      setIsNewQuestion(true);
    }
  }, [initialQuestion]);

  const cancelEdit = () => {
    setQuestion(originalQuestion);
    if (isNewQuestion && cancelAddingNewQuestion) {
      cancelAddingNewQuestion();
    }
    if (editable) setEditable(false);
  };

  const saveQuestion = async () => {
    const flag = optionLabel.show

if(flag){
  let err = {}
  Object.keys(optionLabel.value).forEach(e=>{
    console.log(e);
    
    if(!optionLabel.value[e]) err[e] = "This field is required"
  })
  
  if(Object.keys(err).length > 0) {
    setOptionLabel(p=>({...p,error:err}))
    return
  }
}

    const updatedQuestion = {
      ...question,
      options: question?.options || [],
      sort_order:question?.sort_order || index - 1
    };

  updatedQuestion["option_labels"] =  optionLabel.value
    

    const authHeader = dispatch(add_auth_header({}));

    try {
      if (!updatedQuestion.id) {
        const response = await dispatch(createNewQuestion(authHeader, surveyId, sectionId, updatedQuestion,(status,data)=>{
            if (status) {
                handleNewQuestionSuccess(data);
              }
        }));
     
      } else {
        const response = await dispatch(updateQuestion(authHeader, surveyId, sectionId, updatedQuestion.id, updatedQuestion,(status,data)=>{
            if (status) {
                handleNewQuestionSuccess(data);
              }
        }));
      }
    } catch (error) {
      console.error('Error saving question:', error);
    }
  };

  const handleNewQuestionSuccess = (data) => {
    setQuestion(data);
    setOriginalQuestion(data);
    setEditable(false);
    setIsNewQuestion(false);
    setOptionLabel(initialOptionState)
    if (updateMainQuestionData) {
      updateMainQuestionData(prev => [...prev, data]);
      cancelAddingNewQuestion();
    }
  };

  const handleUpdateSuccess = (data) => {
    setQuestion(data);
    setOriginalQuestion(data);
    setEditable(false);
  };

  const questionDelHandler = async () => {
    try {
      const authHeader = dispatch(add_auth_header({}));
      const success = await dispatch(deleteQuestion(authHeader, surveyId, sectionId, question.id,(status)=>{
        if (status) {
            setShowDelPopup(false);
            if (handleQuestionDeleteFromSection) {
              handleQuestionDeleteFromSection(question.id);
            }
          }
      }));
    } catch (error) {
      console.error('Error deleting question:', error);
    }
  };

  const updateQuestionData = (value, key,response=null) => {
    setQuestion(prev => ({
      ...prev,
      [key]: value,
// ...(response?{response:response}:{})
    }));
    
  };

  const onQuestTypeSelection = (newType) => {
    setQuestion(prev => ({
      ...prev,
      display: newType.display
    }));
  };

  const handleEditButton = ()=>{
    setEditable(true)
 }

 useEffect(()=>{
  if((question.option_labels && Object.keys(question.option_labels).length > 0) && (question.option_labels["left"] ||question.option_labels["right"] ))
    setOptionLabel((p=>({...p,value:question.option_labels,show:editable})))
 },[editable])

  const renderQuestionInput = () => {
    const inputTypes = {
      'Slider': SliderComp,
      'Checkbox': props => <MultiOptions {...props} input="checkbox" />,
      'Radio Button': props => <MultiOptions {...props} input="radio" />,
      'Buttons': props => <MultiOptions {...props} input="buttons" />,
      'Text Box': TextBox,
      'Scale': Scale
    };

    const Component = inputTypes[question.display] || QuestionTypes;
    
  
    
    // <Box
    // sx={{
    //   display:"flex",
    //   justifyContent:"space-between",
    //   alignItems:"center",
    //  ...(question.display?{ width:"65%"}:{})
    // }}>

    return  <Component
      editable={true}
      question={question}
      updateInParent={updateQuestionData}
      onQuestTypeSelection={onQuestTypeSelection}
      showOptionLabel={showOptionLabel}
    />
    // </Box>
  };

  return (
    <>
      <QuestionContainer>
        <Box flex={1}>
          {editable ? (
            <TextField
              fullWidth
              multiline
              value={question.question_txt || ''}
              onChange={(e) => updateQuestionData(e.target.value, "question_txt")}
              variant="outlined"
              size="small"
              placeholder='Type your question here'
            />
          ) : (
            <Typography sx={{ typography: 'body1', fontWeight: 600,textAlign:"left"}}>
              {index}.   {question.question_txt}
            </Typography>
          )}
        </Box>
        
        <ActionContainer>
          {editable && (
            <IconButton size="small" onClick={cancelEdit}>
              <Cancel />
            </IconButton>
          )}
          
          <IconButton size="small" onClick={() => editable ? saveQuestion() : handleEditButton()}>
            {editable ? <Save /> : <Edit />}
          </IconButton>
          
          {!editable && (
            <IconButton size="small" onClick={() => setShowDelPopup(true)}>
              <Delete />
            </IconButton>
          )}
        </ActionContainer>
      </QuestionContainer>

      {
        question.display && question.display !== "Text Box" &&    <Box 
          sx={{
          // position: 'absolute',
          // right: 0,
          // top: '65px',
          // maxWidth: '310px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'start',
          alignItems: 'center'
        }}
          >
        
        {/* <LoadingButton
                loading={0}
                startIcon={optionLabel.show?<CancelRounded/>:<AddCircle/>}
                variant="contained"
                sx={{
                  alignSelf:"start",
                    // backgroundColor: "gray",
                    "&:hover": {
                      backgroundColor: "darkgray",
                    },
                    // width:"200px",
                         padding : "12px 16px"
                  }}
                      style={{
                            background: commonColors.soft_gray,
                          }}
                  onClick={(e) => {
                    e?.stopPropagation()
                    setOptionLabel((p=>({...p,value:initialOptionState.value,show:!p.show})))
                    }
                }
              >
               <span>
              {optionLabel.show?"Remove Option Label": " Add Option Label"}
               </span>
              </LoadingButton>
*/}
          {
           editable && question.display !== "Text Box" && <>
                <Box sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"start",
                alignSelf:"start"
              }}> 

              <Typography textAlign={"start"} variant="body1" fontWeight={500}>
    {optionLabel.show ? "Remove Option Label" : "Add Option Label"}
  </Typography>
  {!optionLabel.show && <AddCircle
        onClick={(e) => {
          e?.stopPropagation()
          setOptionLabel((p=>({...p,value:initialOptionState.value,show:!p.show})))
          }}
  style={{ marginLeft: 8 }} />}
              </Box>
            </>
          }


{
  optionLabel.show &&               <Grid width={400} alignSelf={'start'} container spacing={2} margin={2}>
  <Grid item sm={5} xs={5}>
        <TextField
          required
          label="Left"
          name="left"
          size='small'
          value={optionLabel.value.left}
          onChange={(e)=>{
            e.stopPropagation()
            setOptionLabel((p)=>({...p,error:{...p.error,...(e.target.value?{[e.target.name]:""}:{})},value:{...p.value,[e.target.name]:e.target.value}}))
          }}
          error={optionLabel.error["left"]}
          helperText={optionLabel.error["left"]}
          // fullWidth
          // disabled={loading || data?.addLang}
          multiline
          // minRows={3}
          // maxRows={5}
        />
      </Grid>
  
    <Grid item sm={5} xs={5}>
    <TextField
          required
          label="Right"
          name="right"
          size='small'
          value={optionLabel.value.right}
          onChange={(e)=>{
            e.stopPropagation()
            setOptionLabel((p)=>({...p,error:{...p.error,...(e.target.value?{[e.target.name]:""}:{})},value:{...p.value,[e.target.name]:e.target.value}}))
          }}
          error={optionLabel.error["right"]}
          helperText={optionLabel.error["right"]}
          // fullWidth
          // disabled={loading || data?.addLang}
          multiline
          // minRows={3}
          // maxRows={5}
        />
    </Grid>

    <Grid
        item
                      sm={1}
                      xs={1}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <IconButton
                        onClick={(e) => {
                        e.stopPropagation()
                        setOptionLabel((p=>({...p,value:initialOptionState.value,show:!p.show})))
                        }}
                      >
                        <CancelRounded
                          color="error"
                          fontSize="small"
                        />
                      </IconButton>
                    </Grid>
  </Grid>
}
                  </Box>
        }

      {editable && (
        <InputTypeContainer>
          {renderQuestionInput()}
          

     <StyledSelect
          sx={{
            position:"absolute",
            top:0,
            right:0
          }}
            value={question.display || ''}
            onChange={(e) => onQuestTypeSelection({ display: e.target.value })}
            variant="outlined"
            size="small"
          >
            {['Slider', 'Checkbox', 'Buttons', 'Radio Button', 'Scale', 'Text Box'].map(type => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </StyledSelect>
        </InputTypeContainer>
      )}

      <DialogBox 
        open={showDelPopup}
        setOpen={() => setShowDelPopup(false)}
        handleAgree={questionDelHandler}
        agreeButtonText="Yes"
        content="Are you sure you want to delete this question? This process cannot be undone"
        topHeading="Confirm"
        closeText="No"
      />
    </>
  );
};

export default QuestionComponent;
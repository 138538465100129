
import {
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef,useState } from "react";
import BasePage from "../../commons/components/BasePage/BasePage";
import { useDispatch, useSelector } from "react-redux";
import LabTabs from "../../commons/components/PageTab/LapTabs";
import Information from "./Information";
import Preview from "./Preview";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { compareObjects } from "../../commons/utility";
import { AddUpdateEvent_Place } from "../../commons/components/Settings/actions/settingsActions";

 const AddNewItem = () => {
    const navObj = useSelector((state) => state.nav.selectedNav || {});
    const location = useLocation()
    const history = useHistory()
    const params =new URLSearchParams(location.search)
    const Type = params.get("entity") === "EVENT"?"event":"place"
    const [id,setId] = useState(params.get("id"))
    const [publishState,setPublishState] = useState(false)
    const triggerSaveRef = useRef(null)
    const previousFormValues = useRef({});
    const isSaveTriggered = useRef(false)
    const dispatch = useDispatch()


  const saveInformationOnTabChange = (value) => {    
    if (value === "Preview" && !compareObjects(previousFormValues.current.initial, previousFormValues.current.final)) {
      isSaveTriggered.current = true
      triggerSaveRef.current()
    }
  }


  const handlePublish = (e)=>{
    if(publishState || !id) return
    e?.stopPropagation()
          dispatch(AddUpdateEvent_Place({ enabled:true, id }, true, null, setPublishState));

  }



  return (
    <BasePage title={navObj.name} showBox={true} show_side_filter={true}>
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "32px",
      height: "100%",
      width:"100%",
      // padding: "8px 0px 8px 0px",
      flexGrow: 1,
    }}
  >
    <Box padding={0} marginBottom={10}>
      {/* <Typography variant="body2" color="textSecondary">
        Trip Planner / Listings / Add new {Type}s
      </Typography> */}

      <Stack spacing={0} sx={{ mt: 0,padding:0 }} position={"relative"}>
        <Typography variant="h4" fontWeight="bold">
          Add a {Type}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Add a new place, point-of-interest or tourist attraction.
        </Typography>
        <div style={{ position: "absolute", top: 30, right: 16 }} >
        <Button onClick={(e)=>{
          e.stopPropagation()
          history.goBack()
        }}  sx={{ height:"40px",padding:"0px 16px", mr: 1,backgroundColor:"#EEF0F1",color:"#000000", fontSize:'14px',textTransform:"capitalize",   "&:hover": {
        backgroundColor: "#ABB0B8", 
      },}} >
          Cancel
        </Button>
        <Button
        onClick={handlePublish}
        sx={{height:"40px",padding:"0px 16px", backgroundColor:"#0074E8",color:"#FFFFFF", fontSize:'14px',textTransform:"capitalize",
     "&:hover": {
        backgroundColor: "#005BB5", 
      },
      "&.Mui-disabled": {
        backgroundColor: "#0074E8",  
        color: "#FFFFFF", 
      },
      cursor:publishState ? "not-allowed":"pointer"
        }}>
         {publishState ? "Published" : "Publish" } 
        </Button>
      </div>
      </Stack>


      <Box sx={{ width: "100%", mt: 4 }}>
      <LabTabs
      saveInformationOnTabChange={saveInformationOnTabChange}
      tabList={[
  {
      label:"Information",
    value:"Information"
  },
  {  label:"Preview",
    value:"Preview",
disabled:id?false:true,
msg:"Please save the information first to preview"}
]}
componentList={[
    {
        component:<Preview id={id}  isSaveTriggered={isSaveTriggered}/>,
        value:"Preview"
    },
    {
        component:<Information setPublishState={setPublishState}  setId={setId} id={id} triggerSaveRef={triggerSaveRef} previousFormValues={previousFormValues}/>,
        value:"Information"
    }
]}
tabPannelCss={{
    marginTop:"32px"
}}
/>
      </Box>
    </Box>
    </Box>
    </BasePage>
  );
};

export default AddNewItem;

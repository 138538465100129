import { Box, Typography } from '@mui/material'
import React from 'react'

const Heading = ({title="",count=0}) => {
  return (
    <Box alignSelf={"start"} marginTop={2} display={"flex"} alignItems={"center"}>
    <Box display={"flex"} gap={"4px"} alignItems={"center"}>
    
    <Typography variant="h5"
    sx={{     
fontSize: "20px",
fontWeight: 600,
lineHeight: "30px",
letterSpacing: "-0.66px",
textAlign: "left"
    }}
    
    >{title}</Typography>
                <Typography sx={{
                    height:"24px",
                    width:"24px",
                    borderRadius:"16px",
                    backgroundColor:"#E4E5E7",
                    color:"#000000",
                    textAlign:"center",
                    fontSize:"12px",
                    lineHeight:"20px",
                    letterSpacing:"-0.44px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                }} variant="span" color="textSecondary">
                    {count}
                </Typography>
    </Box>
              </Box>
  )
}

export default Heading

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, IconButton, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AddCircle, FileCopyRounded } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';

// Assuming these utilities and actions still exist in your codebase
import { add_auth_header } from "../../../../../components/Utility/Utility";
import { getSurvey, createNewSection, createNewSurvey, deleteSection, editSectionName, editSurvey } from '../../../../../actions/surveyActions';
import CommonButton from '../../../../../commons/components/UI/CommonButton';
import { commonColors } from '../../../../../components/Utility/Constants';

// Styled components using MUI's styled API
const AddSectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: theme.spacing(2)
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2)
}));

const initialState = {
  name: "",
};

const NewSection = ({ 
  surveyId, 
  currentSections, 
  renderIcon, 
  renderButton, 
  handleSectionAddToSurvey,
  debug 
}) => {
  const dispatch = useDispatch();
  
  const [addSectionPopup, setAddSectionPopup] = useState({
    show: false,
    name: "",
    questions: []
  });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setAddSectionPopup({
      show: false,
      name: "",
      questions: []
    });
  };

  const addNewSection = async () => {
    setLoading(true);
    const { name, questions = [] } = addSectionPopup;
    
    try {
      const authHeader = dispatch(add_auth_header({}));
      const response = await dispatch(createNewSection(authHeader, surveyId, name, questions,(status,data)=>{
        if (status) {
            if (handleSectionAddToSurvey) {
              handleSectionAddToSurvey(data);
            } else if (debug) {
              console.warn('No handleSectionAddToSurvey prop provided');
            }
            handleClose();
          } else if (debug) {
            console.log(`Error while creating section. ${data}. status: ${status}`);
          }
      }));
      
     
    } catch (error) {
      if (debug) console.error('Error creating section:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderAddButton = () => (
    <AddSectionContainer>
      <LoadingButton
        loading={loading}
        loadingPosition="end"
        startIcon={<AddCircle />}
        variant="contained"
        sx={{
          "&:hover": {
            backgroundColor: "darkgray",
          },
          width: '220px',
          padding: '12px 16px'
        }}
        style={{
          background: commonColors.soft_gray,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setAddSectionPopup({ show: true, name: "", questions: [] });
        }}
      >
        Add new section
      </LoadingButton>
    </AddSectionContainer>
  );

  const renderCopyIcon = () => (
    <IconButton
      size="small"
      onClick={() => setAddSectionPopup({ show: true, name: "", questions: [] })}
      sx={{ 
        '&:focus': { outline: 'none' },
        color: 'white'
      }}
    >
      <FileCopyRounded sx={{ fontSize: '1.3em' }} />
    </IconButton>
  );

  return (
    <>
      {renderButton ? renderAddButton() : renderIcon ? renderCopyIcon() : null}

      <Dialog 
        open={addSectionPopup.show} 
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add New Section</DialogTitle>
        <DialogContent>
          <StyledTextField
            autoFocus
            margin="dense"
            label="Section Name"
            type="text"
            fullWidth
            variant="outlined"
            value={addSectionPopup.name}
            onChange={(e) => setAddSectionPopup({
              ...addSectionPopup,
              name: e.target.value
            })}
          />
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            {/* <LoadingButton
              onClick={handleClose}
              variant="outlined"
            >
              Cancel
            </LoadingButton> */}
            <CommonButton
                onClickHandler={handleClose}
                title={"Cancel"}
                // loading={loading}
              />
            {/* <LoadingButton
              onClick={addNewSection}
              loading={loading}
              variant="contained"
              disabled={!addSectionPopup.name.trim()}
            >
              Add
            </LoadingButton> */}
            <CommonButton
                onClickHandler={addNewSection}
                title={"Add"}
                loading={loading}
                disabled={!addSectionPopup.name.trim()}
              />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewSection;
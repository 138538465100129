import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const SliderComp = props => {
    const [selectedIdx, setSelectedIdx] = useState(null);
    const [options, setOptions] = useState([]);
    const [max, setMax] = useState(null);
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const getTrackColor = (index) => {
        if (index <= 1) return '#FF0000';
        if (index === 2) return '#FFA500';
        return '#00FF00';
    };

    const getMarkStyle = () => {
        const getFontSize = () => {
            if (isMobile) return '12px';
            if (isTablet) return '13px';
            return '15px';
        };

        const getTopPosition = () => {
            if (isMobile) return '8px';
            if (isTablet) return '9px';
            return '10px';
        };

        const markStyle = val => (
            <span style={{
                position: 'absolute',
                top: getTopPosition(),
                left: "0px",
                fontSize: getFontSize(),
                fontFamily: 'Lato',
                whiteSpace: 'nowrap',
                transform: isMobile ? 'rotate(-45deg) translateX(-50%)' : 'none',
                transformOrigin: 'left top'
            }}>
                {options[val].value}
            </span>
        );

        let marks = {};
        for (let i = 0; i < options.length; i++) {
            marks[i] = markStyle(i);
        }
        return marks;
    };

    useEffect(() => {
        setMax(props.question.options.length - 1);
        setOptions(props.question.options);
        if (props.debug) console.log(`Response received: ${props.response}`);
        // console.log(props.response , props.question , selectedIdx == null);
        
        if (props.response && props.question && selectedIdx == null) {
            let opIdx = props.question.options.findIndex(o => 
                String(o.value) == String(props.response)
            );
            if (props.debug) console.log(`Option found: ${opIdx}`);
            if (opIdx != -1) setSelectedIdx(opIdx);
        }
    }, [props.question, props.response]);

    const sliderHandler = (index) => {
        setSelectedIdx(index);
        if (props.saveResponse) props.saveResponse(options[index].value);
    };

    const getHandleSize = () => {
        if (isMobile) return 20;
        if (isTablet) return 22;
        return 24;
    };

    const handleSize = getHandleSize();

    return (
        <Box sx={{
            width: '100%',
            px: { xs: 2, sm: 3, md: 4 },
            py: { xs: 4, sm: 5, md: 6 },
            position: 'relative',
            '& .rc-slider-rail': {
                height: { xs: '4px', sm: '5px', md: '6px' },
            },
            '& .rc-slider-track': {
                height: { xs: '4px', sm: '5px', md: '6px' },
            },
            '& .rc-slider-mark': {
                fontSize: { xs: '12px', sm: '13px', md: '15px' },
                marginTop: { xs: '8px', sm: '12px', md: '16px' },
            }
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 2,
                px: 1,
            }}>
                      {
        props?.question?.option_labels?.left &&      
                <Typography
                    sx={{
                        fontSize: { xs: '12px', sm: '13px', md: '15px' },
                    }}
                >
                    {props.question?.option_labels?.left}
                </Typography>

}

{
        props?.question?.option_labels?.right &&
                <Typography
                    sx={{
                        fontSize: { xs: '12px', sm: '13px', md: '15px' },
                    }}
                >
                   {props.question?.option_labels?.right}
                </Typography>
}
            </Box>
       {/* {
        props?.question?.option_labels?.left &&          <Typography
        sx={{
            position: 'absolute',
            left: { xs: '-40px', sm: '-30px', md: '-35px' },
            top: '0%',
            transform: 'translateY(-50%)',
            fontSize: { xs: '12px', sm: '13px', md: '15px' },
            fontFamily: 'Lato',
            color: 'text.secondary',
            fontWeight: 'bold',
        }}
    >
        {props.question?.option_labels?.left}
    </Typography>
       }

{
        props?.question?.option_labels?.right &&  
                <Typography
                    sx={{
                        position: 'absolute',
                        right: { xs: '-40px', sm: '-35px', md: '-40px' },
                        top: '0%',
                        transform: 'translateY(-50%)',
                        fontSize: { xs: '12px', sm: '13px', md: '15px' },
                        fontFamily: 'Lato',
                        color: 'text.secondary',
                        fontWeight: 'bold',
                    }}
                >
                   {props.question?.option_labels?.right}
                </Typography>
} */}
                <Slider
                    min={0}
                    max={max}
                    step={1}
                    disabled={props.disable?true:false}
                    trackStyle={[{
                        height: isMobile ? '4px' : '6px',
                        borderRadius: '3px',
                        background: selectedIdx !== null ? getTrackColor(selectedIdx) : '#E4E6E7',
                    }]}
                    railStyle={{
                        height: isMobile ? '4px' : '6px',
                        borderRadius: '3px',
                        background: '#e0e0e0',
                    }}
                    marks={getMarkStyle()}
                    dotStyle={{
                        display: 'none',
                    }}
                    value={selectedIdx}
                    onChange={sliderHandler}
                    handleStyle={[{
                        width: `${handleSize}px`,
                        height: `${handleSize}px`,
                        borderRadius: '50%',
                        border: '2px solid #4472C4',
                        background: '#fff',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                        transform: 'translateY(-50%)',
                        top: isMobile ? "10px" : "12px",
                        marginTop: '-3px'
                    }]}
                />
            </Box>
        // </Box>
    );
};

export default SliderComp;
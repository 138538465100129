import React from 'react'

const DeleteSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
    <g id="lucide/trash-2">
    <path id="Vector" d="M2.32806 4.98738H14.3659M13.0284 4.98738V14.3502C13.0284 15.0189 12.3596 15.6877 11.6908 15.6877H5.00314C4.33437 15.6877 3.6656 15.0189 3.6656 14.3502V4.98738M5.67191 4.98738V3.64985C5.67191 2.98108 6.34068 2.31231 7.00945 2.31231H9.68453C10.3533 2.31231 11.0221 2.98108 11.0221 3.64985V4.98738M7.00945 8.33123V12.3439M9.68453 8.33123V12.3439" stroke="black" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
  )
}

export default DeleteSvg

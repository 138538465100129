import React, { useState, Fragment, useEffect } from 'react';
import { Controller } from "react-hook-form";
import { 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Checkbox, 
  ListItemText, 
  Input,
  styled 
} from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => 
    prop !== 'minWidth' && prop !== 'maxWidth'
})(({ theme, minWidth = 220, maxWidth = 300 }) => ({
  margin: theme.spacing(1),
  minWidth: `${minWidth}px`,
  maxWidth: `${maxWidth}px`,
  marginLeft: '5%',
  transform: 'translateY(-40%)'
}));

const SingleSelectDropdown = ({
  label,
  control,
  errors,
  allObjects = [],
  selectedObject = {},
  onChange,
  debug = false,
  displayLabel,
  minWidth = 220,
  maxWidth = 300,
  ...props
}) => {
  
  const [localAllObjects, setLocalAllObjects] = useState(allObjects);
  const [localSelectedObject, setLocalSelectedObject] = useState(selectedObject);

  useEffect(() => {
    setLocalAllObjects(allObjects);
    setLocalSelectedObject(selectedObject);

    if (debug) {
      console.log(`useEffect: selectedObject: ${JSON.stringify(selectedObject)}, allObjects: ${JSON.stringify(allObjects)}`);
    }
  }, [allObjects, selectedObject, debug]);

  const handleChange = (event) => {
    const val = event.target.value;
    
    if (debug) console.log(`handleChange: ${JSON.stringify(val)}`);
    
    const newSelectedObj = localAllObjects.find(obj => obj.id == val) || {};

    setLocalSelectedObject(newSelectedObj);

    if (onChange) {
      if (debug) console.log(`calling onChange: ${JSON.stringify(newSelectedObj)}`);
      onChange(newSelectedObj);
    }
  };

  return (
    <Fragment>
      <StyledFormControl 
        minWidth={minWidth} 
        maxWidth={maxWidth}
        {...props}
      >
        {localSelectedObject && Object.keys(localSelectedObject).length > 0 && (
          <InputLabel id="single-checkbox-label" disabled>
            {displayLabel || label}
          </InputLabel>
        )}
        
        <Controller
          control={control}
          name={label}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              value={localSelectedObject.id || ''}
              onChange={handleChange}
              renderValue={() => localSelectedObject.label || ''}
              labelId="single-checkbox-label"
              id="single-checkbox"
              input={<Input />}
              MenuProps={MenuProps}
            >
              {localAllObjects.map((obj) => (
                <MenuItem key={obj.id} value={obj.id}>
                  <Checkbox
                    checked={localSelectedObject.id == obj.id}
                    checkedIcon={<CheckBoxOutlinedIcon style={{color: "#7ea63f"}}/>}
                  />
                  <ListItemText primary={obj.label} />
                </MenuItem>
              ))}
            </Select>
          )}
        />
        
        {errors && (
          <span style={{color:'red', fontSize:'0.8em'}}>
            {errors.status?.type === 'required' && "Status is required"}
            {errors.language?.type === 'required' && "Language is required"}
          </span>
        )}
      </StyledFormControl>
    </Fragment>
  );
};

export default SingleSelectDropdown;
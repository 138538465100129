import React, { useState, Fragment, useEffect } from 'react';
import { Edit, CheckCircle } from '@mui/icons-material';
import { 
  IconButton, 
  TextField, 
  styled 
} from '@mui/material';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  transform: 'translateY(-2px)',
  '&:focus': {
    outline: "none"
  }
}));

const EditableField = ({
  value: initialValue,
  name,
  placeholder,
  onChange,
  inputWidth,
  iconColor = "#7ea63f",
  maxRows,
  minRows,
  ...props
}) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleToggleEdit = () => {
    if (editing && onChange) {
      onChange(value);
    }
    setEditing(prevState => !prevState);
  };

  return (
    <Fragment>
      {editing ? (
        <TextField
          size="small"
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          fullWidth
          sx={{
            width: inputWidth || "100%"
          }}
          minRows={minRows || 1 }
          maxRows={maxRows || 1 }
          multiline
        //   {...props}
        />
      ) : (
        <span>{value}</span>
      )}

      &nbsp;&nbsp;
      <StyledIconButton 
        size="small" 
        onClick={handleToggleEdit}
      >
        {editing ? (
          <CheckCircle style={{ color: iconColor }} />
        ) : (
          <Edit style={{ color: iconColor }} />
        )}
      </StyledIconButton>
    </Fragment>
  );
};

export default EditableField;
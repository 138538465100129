import React, { useEffect, useState, useMemo } from "react";
import { Box, Divider, Grid, Paper, Skeleton } from "@mui/material"; // Import Skeleton
import { TP_API_KEY } from "../../components/Constants/Constants";

const Preview = ({ id, isSaveTriggered }) => {
  const [url, setUrl] = useState("");

  const iframeUrl = useMemo(() => {
    return `${process.env.REACT_APP_TRIP_PLANNER_BASE_URL}?path=description&ID=${id}&server=${process.env.REACT_APP_Mode}&key=${TP_API_KEY}`;
  }, [id]);

  useEffect(() => {
    if (isSaveTriggered?.current) {
      const timer = setTimeout(() => {
        setUrl(iframeUrl); 
      }, 1000);

      return () => clearTimeout(timer); 
    } else {
      setUrl(iframeUrl); 
    }
  }, [isSaveTriggered?.current, iframeUrl]); 

  const containerStyles = {
    display: "flex",
    flexDirection: "column",
    gap: "32px"
  };

  const paperStyles = {
    py: 5,
    backgroundColor: "#f6f6f6",
    borderRadius: 2,
    height: "100%",
    width: "100%"
  };

  const boxStyles = {
    backgroundColor: "white",
    borderRadius: 4,
    overflow: "hidden",
    boxShadow: 3,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px",
    margin: "auto"
  };

  const iframeStyles = {
    border: 0,
    width: "100%",
    height: "190vh"
  };

  return (
    <Grid container sx={containerStyles}>
      <Divider sx={{ my: 1 }} />
      <Paper sx={paperStyles}>
        <Box sx={boxStyles}>
          {/* {!url ? (
            // Display Skeleton when url is not yet set
            <Skeleton variant="rectangular" width="100%" height="190vh" />
          ) : ( */}
            <iframe
              src={url}
              style={iframeStyles}
              allowFullScreen
              loading="lazy"
            />
          {/* )} */}
        </Box>
      </Paper>
    </Grid>
  );
};

export default Preview;

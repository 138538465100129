import React, { useEffect, useRef } from 'react'
import {
  Box,
  Typography,
} from "@mui/material";
import Heading from './Heading';
import CommonTableUI from '../../commons/components/Settings/components/CommonTableUI';
import { useDispatch, useSelector } from 'react-redux';
import { changeLoadingStatePlaceAndEvent, delete_Place_Events, fetchPlaceAndEventData } from '../../commons/components/Settings/actions/settingsActions';
import CommonSingleRow from '../../commons/components/Settings/components/CommonSingleRow';
import { formatDate } from '../../commons/utility';
import { colorWithType } from '../../components/Utility/Constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { CustomRating } from './CustomRating';


const filterRoute = `/trip-planner`
const columns = [
  { name: "Date",orderBy:"created_at"},
  { name: "Time",orderBy:"time"},
  { name: "Name",orderBy:"name"},
  { name: "Venue",orderBy:"venue"},
  { name: "Category",orderBy:"category"},
  // { name: "Distance",orderBy:"distance"},
  { name: "Rating",orderBy:"rating"},
  { name: "Status",orderBy:"status"},
  {name:"Action"}
];

const dimension = {
  created_at: { maxWidth: "150px", minWidth: "150px" },
  time: { maxWidth: "140px", minWidth: "140px" },
  name: { maxWidth: "170px", minWidth: "170px" },
  venue: { maxWidth: "120px", minWidth: "120px" },
  category: { maxWidth: "150px", minWidth: "150px" },
  rating: { maxWidth: "120px", minWidth: "120px" },
  status: { maxWidth: "100px", minWidth: "100px" }
};



const EventTab = () => {
  const APITrigger = useRef(null);

  const navObj = useSelector((state) => state.nav.selectedNav || {});
  const selectedFilters = useSelector(
    (state) => state.filter.selectedFilters[`${filterRoute}`] || []
  );

  const selectedHotel = useSelector(
          (state) => state.filter.selectedHotel || {}
        );
      const eventDataSelector = state => state.settings?.PlaceAndEvent?.EVENT?.data || []
      const eventCount = useSelector(state => state.settings?.PlaceAndEvent?.EVENT?.count || 0)
      const eventpage =  useSelector(state => state.settings?.PlaceAndEvent?.EVENT?.page || 0)
      const eventLoadingSelector = state => state.settings?.PlaceAndEvent?.EVENT?.loading
      const eventErrorSelector = state => state.settings?.PlaceAndEvent?.EVENT?.error



  const dispatch = useDispatch()
  const navigate = useHistory()

      useEffect(() => {
        if (APITrigger.current) clearTimeout(APITrigger.current);
        if (Object.keys(selectedHotel).length > 0 
        &&   selectedFilters.length > 0
      ) {
          APITrigger.current = setTimeout(() => {

          dispatch(changeLoadingStatePlaceAndEvent(true,"EVENT"))
          dispatch(fetchPlaceAndEventData(0,changeLoadingStatePlaceAndEvent,false,null,null,{
            filterkey:`${filterRoute}`,
            entityType:"EVENT"
          }));
          }, 0);
        }

      }, [selectedFilters]);


            const fetchEvents = (newPage)=>{
              dispatch(changeLoadingStatePlaceAndEvent(true,"EVENT"))
              dispatch(fetchPlaceAndEventData(newPage,changeLoadingStatePlaceAndEvent,false,null,null,{
                filterkey:`${filterRoute}`,
                entityType:"EVENT"
              }));
            }

            const handleEdit = (data)=>{
              navigate.push(`/trip-planner/manage?id=${data.id}&entity=EVENT`) 
            }

                  const hadleDelete = (data)=>{
                    dispatch(delete_Place_Events(data.id,{
                      entityType:data.type,
                    }))
                  }
          

            const menuItems = [
              { label: 'Edit', onClick: handleEdit },
              { label: 'Delete', onClick: hadleDelete },
              
            ]

            const RowComponent = ({row={}}) => {
              let payload = {
                created_at:formatDate(row.created_at),
                time:formatDate(row.start_time),
                name:row.name,
                venue:row.venue_name,
                category:row.category?.display_name,
                // distance:row.distance || "--",
                rating:  CustomRating(row.rating) ,
                status: row.status ? <Box sx={{
                  display:'inline-block',
                  borderRadius:"16px",
                  background:colorWithType[row.status]?.bg || colorWithType["new"]?.bg,
                  color:colorWithType[row.status]?.tx || colorWithType["new"]?.tx,
                  padding:"4px 8px"
                 }}>
              <Typography
              sx={{
                fontSize:"12px",
                lineHeight:"18px",
                letterSpacing:"0.4px"
              }}
              >
                {row.status}
              </Typography>
                 </Box>:"",
            }

            const css = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'capitalize'}
            return(
              <>
          <CommonSingleRow
            key={`${row?.id}`}
            payload={ payload}
            showMenu={true}
            menuItems={menuItems}
            id={row.id}
            fullData = {row}
            commonCss={css}
            dimension={dimension}
            // imageList ={row?.images || []}
          />
                </>
              );
            };

    return <Box 
    sx={{
      display:"flex",
      flexDirection:"column",
      justifyContent:'start',
      alignItems:"start",
      gap:"12px",
      width:'100%'
    }}
    >
 <Heading title="Events" count={9} />
<CommonTableUI
columns={columns}
fetchData={fetchEvents}
dataSelector={eventDataSelector}
loadingSelector={eventLoadingSelector}
errorSelector={eventErrorSelector}
isEditPermission={true}
RowComponent = {RowComponent}
minRow={20}
count={eventCount}
page={eventpage}
initialFetch={false}
title={"Events"}
heading={false}
maxHeight={"1096px"}
serverSidePagination={true}
/>

 </Box>
}

export default EventTab
import React, { useState, useCallback } from 'react';
import { 
  Button, 
  Box, 
  Typography, 
  Divider, 
  Stack,
  Alert,
  Snackbar,
  Container,
  Paper,
  IconButton,
  Tooltip
} from '@mui/material';
import { 
  Upload as UploadIcon, 
  Download as DownloadIcon, 
  Code as CodeIcon,
  TextFields as TextFieldsIcon
} from '@mui/icons-material';
import HtmlCodeEditor from './HtmlCodeEditor';
import Preview from './Preview';
// import FroalaEditorWrapper from './FloaraEditor';

const HtmlEditor = () => {
  const [isRichEditor, setIsRichEditor] = useState(true);
  const [htmlContent, setHtmlContent] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');

  const toggleEditor = () => setIsRichEditor(!isRichEditor);

  const handleHtmlUpdate = (updatedHtml) => {
    setHtmlContent(updatedHtml);
  };

  const showNotification = (message, type = 'success') => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);
  };

  const handleUpload = useCallback(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.html,.txt';
    
    input.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          try {
            setHtmlContent(event.target.result);
            // console.log(event.target.result);
            
            showNotification('File uploaded successfully');
          } catch (error) {
            showNotification('Error reading file', 'error');
          }
        };
        reader.readAsText(file);
      }
    };
    
    input.click();
  }, []);

  const handleDownload = useCallback(() => {
    try {
      const blob = new Blob([htmlContent], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'content.html';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      showNotification('File downloaded successfully');
    } catch (error) {
      showNotification('Error downloading file', 'error');
    }
  }, [htmlContent]);

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            HTML and Rich Text Editor
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            Edit content in rich text or HTML mode, with file upload and download support
          </Typography>
        </Box>

        <Paper sx={{ p: 2, mb: 3 }} elevation={2}>
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            justifyContent="space-between" 
            alignItems="center"
            spacing={2}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={isRichEditor ? <CodeIcon /> : <TextFieldsIcon />}
              onClick={toggleEditor}
            >
              {isRichEditor ? 'HTML Editor' : 'Rich Text Editor'}
            </Button>

            <Stack direction="row" spacing={2}>
              <Tooltip title="Upload HTML file">
                <Button
                  variant="outlined"
                  startIcon={<UploadIcon />}
                  onClick={handleUpload}
                >
                  Upload
                </Button>
              </Tooltip>
              <Tooltip title="Download as HTML">
                <span>
                  <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownload}
                    disabled={!htmlContent}
                  >
                    Download
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          </Stack>
        </Paper>

        <Paper sx={{ mb: 3 }} elevation={3}>
          {isRichEditor ? (
            // <QuillEditorWrapper 
            //   initialContent={htmlContent} 
            //   onChange={setHtmlContent} 
            // />
          //   <TinyMCEEditor 
          //   initialContent={htmlContent} 
          //   onChange={setHtmlContent} 
          // />
        //   <CKEditorWrapper
        //   initialContent={htmlContent}
        //   onChange={setHtmlContent}
        //   height="500px"
        //   editorConfig={{
        //     placeholder: 'Start typing here...',
        //     extraPlugins: [],
        //   }}
        // />
        <div>
        {/* <FroalaEditorWrapper
          initialContent={htmlContent}
          onChange={handleHtmlUpdate}
          config={{
            // toolbarButtons: ['bold', 'italic', 'underline', 'formatOL', 'formatUL'],
            quickInsertTags: [],
          }}
        /> */}
        {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}
      </div>
          ) : (
            <HtmlCodeEditor
              initialContent={htmlContent}
              onContentChange={handleHtmlUpdate}
            />
          )}
        </Paper>

        <Divider sx={{ my: 3 }} />

        <Paper sx={{ p: 2 }} elevation={2}>
          <Preview htmlContent={htmlContent} />
        </Paper>

        <Snackbar
          open={showAlert}
          autoHideDuration={3000}
          onClose={() => setShowAlert(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert 
            onClose={() => setShowAlert(false)} 
            severity={alertType}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {alertMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default HtmlEditor;
import React from 'react';
import { Box, Typography, Backdrop } from '@mui/material';
import { keyframes } from '@mui/system';

// Keyframe animations
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const dotPulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
`;

const barLoad = keyframes`
  0% { transform: scaleX(0); opacity: 1; }
  100% { transform: scaleX(1); opacity: 0.5; }
`;

const LoadingOverlay = ({ isLoading, message = "Please wait..." }) => {
  // return null
  if (!isLoading) return null;

  return (
    <Backdrop
      open={isLoading}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(3px)',
        transition: 'all 0.2s ease-in-out',
        animation: `${fadeIn} 0.2s ease-out`,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          // backgroundColor: 'rgba(0, 0, 0, 0.6)',
          borderRadius: '12px',
          padding: '24px 48px',
          // backdropFilter: 'blur(2px)',
          // boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* Animated dots loader */}
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            mb: 1,
          }}
        >
          {[0, 1, 2].map((index) => (
            <Box
              key={index}
              sx={{
                width: '12px',
                height: '12px',
                backgroundColor: 'primary.main',
                borderRadius: '50%',
                animation: `${dotPulse} 1s ease-in-out infinite`,
                animationDelay: `${index * 0.2}s`,
              }}
            />
          ))}
        </Box>

        {/* Animated progress bar */}
        <Box
          sx={{
            width: '120px',
            height: '3px',
            // backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '2px',
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'primary.main',
              animation: `${barLoad} 1s ease-in-out infinite`,
              transformOrigin: 'left',
            }}
          />
        </Box>

        <Typography
          variant="body2"
          sx={{
            // color: 'white',
            fontWeight: 500,
            textAlign: 'center',
            letterSpacing: '0.5px',
            opacity: 0.9,
          }}
        >
          {message}
        </Typography>
      </Box>
    </Backdrop>
  );
};

export default LoadingOverlay;
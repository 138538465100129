import React, { useState } from "react";
import {
  TextField,
  FormControl,
  FormLabel,
  Box,
  Grid,
  MenuItem,
  Checkbox,
  Typography,
  IconButton,
  Divider,
  InputAdornment
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CommonButton from "../../UI/CommonButton";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import { commonFetchData } from "../../../utility";
import { AddUpdateAndDeleteSurvey } from "../../../../apps/Survey/action/SurveyAction";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import LoadingButton from '@mui/lab/LoadingButton';
import { Clear, Share } from "@material-ui/icons";
import { setGlobalMessage } from "../../../../actions/dashboardActions";



const ShareSurveyForm = ({ closeModal,isEdit=false,data }) => {
    const initialState = {
     guest_name:"",
     share_by_email:""
      };
      const [bodyData, setbodyData] = useState(initialState);
      const [loading, setLoading] = useState(false);
      const [link,setLink] = useState('');
      const [formError, setFormError] = useState({
        guest_name:"",
      });
      const selectedHotel = useSelector(
          (state) => state.filter.selectedHotel || {}
        );
      const dispatch = useDispatch();
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        if (formError[name] && `${value}`.trim())
        setFormError((prev) => ({ ...prev, [name]: "" }));
        setbodyData({ ...bodyData, [name]: value });
      };
    
      const isValid = () => {
        let valid = true;
        let req = ["guest_name"]
       req.forEach((key) => {
          if (bodyData[key].length == 0) {
            valid = false;
            setFormError((prev) => ({ ...prev, [key]: "This field is required" }));
          }
        });
        return valid;
      };
    
      const copyToClipBoard = (text,message =`Url copied to clipboard`,e )=>{
        navigator.clipboard
        .writeText(text)
        .then(() => {
          dispatch(
            setGlobalMessage({
              mess: message,
              type: "success",
            })
          );
        })
        .catch((err) => {
          dispatch(
            setGlobalMessage({
              mess: `Unable to copy to clipboard: ${err}`,
              type: "error",
            })
          );
        });
      }


      const handleSubmit = async (type) => {
        try {
      
          if (type === "gen") {
            setLoading(true);
            const urlData = await commonFetchData(
              `/survey/${data?.group?.id}/generate-link/?hotel=${selectedHotel?.hotel_code}`,
              { req: "post", onErrorData: {}, body: bodyData }
            );
      
            if (urlData.url) {
              setLink(urlData.url);
            } else {
                dispatch(
                    setGlobalMessage({
                      mess: type === 'gen'?"Link is not generated.":"Something went wrong.",
                      type: "error",
                    }))
            }
          } else if (type === "share") {
            console.log("Sharing executed");
          }
        } catch (error) {
          console.error(error);
          dispatch(
            setGlobalMessage({
              mess: type === 'gen'?"Link is not generated.":"Something went wrong.",
              type: "error",
            }))
        } finally {
          if (type === "gen") setLoading(false); 
        }
      };
    
      return (
        <FormControl
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            rowGap: 2,
            width: { xs: "100%", sm: "600px" },  // Adjusted width for responsiveness
            margin: "auto", // Center the form
            // minHeight:"250px"
          }}
        >
          <FormLabel>Share</FormLabel>
    
  <Grid container spacing={2}>
<Grid item sm={12} xs={12}>
          <TextField
            // required
            label="Guest Name"
            name="guest_name"
            value={bodyData.guest_name}
            onChange={handleChange}
            error={formError["guest_name"]}
            helperText={formError["guest_name"]}
            fullWidth
            disabled={loading}
            multiline
          />
        </Grid>

        {/* <Grid item sm={12} xs={12}>
          <TextField
            label="Email"
            name="share_by_email"
            value={bodyData.share_by_email}
            onChange={handleChange}
            error={formError["share_by_email"]}
            helperText={formError["share_by_email"]}
            fullWidth
            disabled={loading}
            multiline
          />
        </Grid> */}

        {/* <Grid item sm={3} xs={3}>
        <LoadingButton
        loading={0}
        loadingPosition="end"
        endIcon={<Share/>}
        variant="contained"
        sx={{
            backgroundColor: "gray",
            "&:hover": {
              backgroundColor: "darkgray",
            },
          }}
          onClick={(e)=>{
            e.stopPropagation()
            handleSubmit("share")
    }}
      >
       <span>
       Share
       </span>
      </LoadingButton>
        </Grid> */}
{
  link && <>
        <Grid item sm={10} xs={10}>
        <TextField
      label="Survey Link"
      name="Link"
      value={link}
      fullWidth
      disabled={true}  
      multiline
      minRows={2}
      maxRows={4}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={(e)=>{
              e?.stopPropagation()
              setLink("")
            }} edge="end">
              <Clear />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
           
      </Grid>
      <Grid item sm={2} xs={2}>
    <IconButton  disabled={!link} onClick={(e)=>{
        e.stopPropagation()
        copyToClipBoard(link)
    }} ><ContentCopyIcon/></IconButton>
      </Grid>
  </>
}


        <Grid item sm={12} xs={12} display={"flex"} gap={2} justifyContent={"center"}>
            {/* <CommonButton
              loading={loading}
              onClickHandler={()=>  handleSubmit("share")}
              title={"Send To Email"}
            /> */}
            {
              loading ? (
              <Stack sx={{ color: "grey.500" }} spacing={2} direction="row">
                <CircularProgress color="success" />{" "}
              </Stack>
            ) : (
              <CommonButton
              onClickHandler={()=>  handleSubmit("gen")}
                title={"Generate Link"}
                loading={loading}
              />
            )
          }
    </Grid>
  </Grid>
        </FormControl>
      );
    };
    

export default ShareSurveyForm

import React, { useEffect, useState } from 'react';
import CreditsFooter from '../../../../../components/UI/CreditsFooter';

// MUI imports
import { Button, Typography, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import SelectDropdown from '../../../../../components/UI/dropdown/selectDropdown';


const styles = {
  root: {
  //   background: "#f3f7ff",
  // background: "linear-gradient(to right, #f9f9f9, #e3eefa)",
  backgroundColor:"#F9FAFA",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  container: {
    maxWidth: "800px",
    width: "100%",
    borderRadius: "16px",
    boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
    padding: "30px 40px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  header: {
    marginBottom: "10px",
  },
  title: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: "28px",
    fontWeight: "600",
    color: "#344767",
    margin: 0,
  },
  description: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: "14px",
    fontWeight: "400",
    color: "#778798",
    margin: 0,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  button: {
  //   backgroundColor: "#4472C4",
  marginTop:"10px",
  backgroundColor:"#E4E6E7",
    borderRadius: "8px",
    fontFamily: "'Nunito', sans-serif",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "black",
    textTransform: "none",
    padding: "10px 20px",
    transition: "all 0.3s ease",
    cursor: "pointer",
    border: "none",
    "&:hover": {
      backgroundColor: "#355a9e",
    },
    "&:disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
    },
  },
  footer: {
    marginTop: "20px",
    textAlign: "center",
    fontSize: "12px",
    color: "#778798",
  left:"0px"
  },
};

function GuestInfoForm(props) {
  const [formData, setFormData] = useState({
    language: props.selectedLang?.code || ""
  });

  useEffect(() => {
    if (props.selectedLang?.code) {
      setFormData(prev => ({
        ...prev,
        language: props.selectedLang.code
      }));
    }
  }, [props.selectedLang]);

  const handleFormData = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value }); 
    props.handleSubmit({language:event.target.value})
  };

  

  return (
    <div style={styles.root}>
      <div style={styles.container}>
        <div style={styles.header}>
          <Typography style={styles.title}>Select Language</Typography>
          {/* <Typography style={styles.description}>
            Please provide your personal information before filling the survey.
          </Typography> */}
        </div>
        <form style={styles.form}>
          <FormControl style={styles.formControl}>
            <InputLabel id="language-select-label">Language</InputLabel>
            <Select
              labelId="language-select-label"
              id="language-select"
              value={formData.language}
              name="language"
              label="Language"
              onChange={handleFormData}
            >
              {props.surveyLanguages?.map((lang) => (
                <MenuItem key={lang.code} value={lang.code}>
                  {lang.label}
                </MenuItem>
              ))}
            </Select>
            <Button
            style={styles.button}
            disabled={props.guestInfoSubmitting}
            onClick={() => props.handleStart()}
          >
            Start Survey
          </Button>
          </FormControl>
        </form>
      </div>
    </div>
  );
}

export default GuestInfoForm;

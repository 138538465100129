import React, { useEffect, useState } from 'react';
import { Button as MuiButton, Box, useTheme, useMediaQuery, Typography } from '@mui/material';

const Button = (props) => {
    const [options, setOptions] = useState([]);
    const [checkedIdx, setCheckedIdx] = useState(null);
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const getButtonColor = (index) => {
        if (index <= 1) return '#FF0000';  // Red for options 1-2
        if (index === 2) return '#FFA500';  // Orange for option 3
        return '#00FF00';  // Green for options 4-5
    };

    useEffect(() => {
        setOptions(props.question.options);
        
        if (props.debug) console.log(`Response received: ${props.response}`);
        if (props.response && props.question && checkedIdx == null) {
            let opIdx = props.question.options.findIndex(
                (o) => String(o.value) === String(props.response)
            );
            if (props.debug) console.log(`Option found: ${opIdx}`);
            if (opIdx !== -1) setCheckedIdx(opIdx);
        }
    }, [props.question, props.response]);

    const clickHandler = (e, index) => {
        setCheckedIdx(index);
        if (props.saveResponse) props.saveResponse(options[index].value);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems:"center",
                justifyItems:"center",
                gap: { xs: 1, sm: 1.5, md: 2 },
                width: { xs: '100%', sm: 'auto' }
            }}
        >
         {props.showOptionLabel != false && props?.question?.option_labels?.left && 
                    <Typography
                        sx={{
                            // position: 'absolute',
                            left: { xs: '-60px', sm: '-30px', md: '-35px' },
                            top: '0',
                            fontSize: { xs: '12px', sm: '13px', md: '15px' },
                        }}
                    >
                        {props.question.option_labels.left}
                    </Typography>
                }

         <Box 
         sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                flexWrap: { sm: 'wrap' },
                justifyContent: 'start',
                alignItems:"center",
                gap: { xs: 1, sm: 1.5, md: 2 },
                // marginTop: { xs: 1, sm: 1.5, md: 2 },
                width: '100%',
                '& > button': {
                    width: { xs: '100%', sm: 'auto' }
                }
            }}
         >
         {options.map((option, i) => (
                <MuiButton
                style={{
                    background: checkedIdx === i 
                    ? `${getButtonColor(i)}25`
                    : 'transparent',
                }}
                disabled={props.disable?true:false}
                    key={i}
                    variant={checkedIdx === i ? 'contained' : 'outlined'}
                    color={checkedIdx === i ? 'primary' : 'inherit'}
                    onClick={(e) => clickHandler(e, i)}
                    sx={{
                        padding: {
                            xs: '8px 16px',
                            sm: '10px 20px'
                        },
                        fontSize: {
                            xs: '14px',
                            sm: '15px',
                            md: '16px'
                        },
                        minHeight: {
                            xs: '44px',
                            sm: '40px'
                        },
                        borderRadius: '8px',
                        textTransform: 'none',
                        fontWeight: checkedIdx === i ? 'bold' : 'normal',
                        border: checkedIdx === i 
                            ? `2px solid ${getButtonColor(i)}`
                            : '1px solid rgba(0, 0, 0, 0.23)',
                        position: 'relative',
                        overflow: 'hidden',
                        transition: 'all 0.2s ease-in-out',
                        whiteSpace: { xs: 'normal', sm: 'nowrap' },
                        '&:hover': {
                            backgroundColor: checkedIdx === i 
                                ? `${getButtonColor(i)}25`
                                : `${getButtonColor(i)}10`,
                            borderColor: getButtonColor(i),
                        },
                        '&::after': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: { xs: '3px', sm: '4px' },
                            height: '100%',
                            backgroundColor: getButtonColor(i),
                            opacity: checkedIdx === i ? 1 : 0.5,
                        },
                        // Touch target improvements for mobile
                        '@media (hover: none)': {
                            '&:hover': {
                                backgroundColor: checkedIdx === i 
                                    ? `${getButtonColor(i)}15`
                                    : 'transparent',
                            }
                        }
                    }}
                >
                    {option.value}
                </MuiButton>
            ))}
         </Box>

         {props.showOptionLabel != false && props?.question?.option_labels?.right && 
                    <Typography
                        sx={{
                            // position: 'absolute',
                            right: { xs: '-40px', sm: '-35px', md: '-40px' },
                            top: '0',
                            fontSize: { xs: '12px', sm: '13px', md: '15px' },
                        }}
                    >
                        {props.question.option_labels.right}
                    </Typography>
                }
        </Box>
    );
};

export default Button;
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import BasePage from "../../../commons/components/BasePage/BasePage";
import { useDispatch, useSelector } from "react-redux";
import CuratedListForm from "./CuratedListForm";
import OptionList from "./OptionList";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { commonFetchData } from "../../../commons/utility";
import LoadingOverlay from "../../../commons/components/Loader/LoadingOverlay";
import { setGlobalMessage } from "../../../actions/dashboardActions";

const INITIAL_DATA = {
  name: "",
  description: "",
  personalize: false,
  auto_populate: false,
  enabled: false
};

const containerStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  height: "100%",
  width: "100%",
  flexGrow: 1,
};

const buttonBaseStyles = {
  height: "40px",
  padding: "0px 16px",
  fontSize: '14px',
  textTransform: "capitalize",
};

const cancelButtonStyles = {
  ...buttonBaseStyles,
  mr: 1,
  backgroundColor: "#EEF0F1",
  color: "#000000",
  "&:hover": {
    backgroundColor: "#ABB0B8",
  },
};

const publishButtonStyles = {
  ...buttonBaseStyles,
  backgroundColor: "#0074E8",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#005BB5",
  },
};

const AddEditCuratedList = () => {
  const navObj = useSelector((state) => state.nav.selectedNav || {});
  const triggerSaveRef = useRef(null);
  const idsRef = useRef({});
  const selectAllRef = useRef(false);
  const [initialState, setInitialState] = useState(INITIAL_DATA);
  const [loading, setLoading] = useState(false);
  
  const selectedHotel = useSelector((state) => state.filter.selectedHotel || {});
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  
  const id = new URLSearchParams(location.search).get("id");

  const handleCancel = useCallback((e) => {
    e.stopPropagation();
    history.goBack();
  }, [history]);

  const handlePublish = useCallback((e) => {
    e.stopPropagation();
    if (triggerSaveRef.current) {
      triggerSaveRef.current();
    }
  }, []);

  useEffect(() => {
    let isMounted = true;

    const fetchCuratedListData = async () => {
      if (!id || !selectedHotel.hotel_code) return;

      setLoading(true);
      try {
        const response = await commonFetchData(
          `/planner/places-events/groups/${id}/?hotel=${selectedHotel.hotel_code}`,
          { req: "get", onErrorData: {} }
        );

        if (!isMounted) return;

        if (Object.keys(response).length > 0) {
          setInitialState({
            name: response.name,
            description: response.description,
            auto_populate: response.auto_populate,
            personalize: response.personalize,
            event_or_place_list: response?.event_or_place_list || [],
            enabled: response.settings?.enabled || false
          });
        } else {
          dispatch(setGlobalMessage({
            mess: "No data found",
            type: 'error'
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        dispatch(setGlobalMessage({
          mess: "Error loading data",
          type: 'error'
        }));
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchCuratedListData();

    return () => {
      isMounted = false;
    };
  }, [id, selectedHotel.hotel_code, dispatch]);

  return (
    <BasePage title={navObj.name} showBox={true} show_side_filter={true}>
      <LoadingOverlay isLoading={loading} message="" />
      <Box sx={containerStyles}>
        <Box sx={{ p: 0 }}>
          <Stack spacing={0} sx={{ mt: 0 }} position="relative">
            <Typography variant="h4" color="textPrimary">
              Add a list
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Add a new place, point-of-interest or tourist attraction.
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                position: "absolute",
                top: 30,
                right: 16,
                translate: "0 -50%"
              }}
            >
              <Button
                onClick={handleCancel}
                sx={cancelButtonStyles}
              >
                Cancel
              </Button>
              <Button
                onClick={handlePublish}
                sx={publishButtonStyles}
              >
                Publish
              </Button>
            </Stack>
          </Stack>

          <Divider sx={{ my: 3 }} />

          <CuratedListForm
            selectALlRef={selectAllRef}
            triggerSaveRef={triggerSaveRef}
            idsRef={idsRef}
            data={initialState}
          />

          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            marginBottom: 10
          }}>
            <OptionList
              selectALlRef={selectAllRef}
              idsRef={idsRef}
              dataRef={initialState}
            />
          </Box>
        </Box>
      </Box>
    </BasePage>
  );
};

export default AddEditCuratedList;
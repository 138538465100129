import React, {useState, Fragment} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { reformat_date_str } from '../Utility/Utility';
import ItemAddButton from './buttons/itemAddButton';
import ConfirmationDialog from '../UI/confirmationDialog';
import Circle from '../UI/circularProgress';
import { faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import css from './UI.module.css';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "white",
    fontFamily: 'nunito',
    color: '#54524f',
    paddingLeft: '40px',
    top: 0,
    position: "sticky",
    zIndex: 2
  },
  body: {
    zIndex: 1,
    paddingLeft: '40px',
    color: '#6e6b67',
    fontFamily: 'nunito',
  },
}))(TableCell);

const useStyles = makeStyles({
  tableContainer: {
    borderRadius: '12px',
    minWidth: props => props.minWidth,
    maxHeight: props =>props.maxHeight,
    marginRight: '1rem'
  },
  table: {
    borderCollapse: 'separate',
    backgroundColor: props => props.backgroundColor
  },
  iconButton:{
    marginTop: '15px',
    '&:focus' :{
      outline : 'none'
    }
  }
});

export default function StyledTable(props) {
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedObject,setSelectedObject] = useState(null);
  const [showDeletePopup,setShowDeletePopup] = useState(false);

  const clickHandler = (object) => event => {
    setSelectedObject(object);
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getFieldValue = (obj, path) => {
    return path.split(".").reduce((o, key) => o && o[key] ? o[key] : null, obj);
  }

  const renderList = (object,field, idx) => {

    let values = [];

    if (props.value_processors && props.value_processors[field]){

      for (let v of getFieldValue(object, field)){
        values.push(props.value_processors[field](v));
      }
          
    } else {
      // for(const nestedObject of object?.[field]){
      //   values.push(nestedObject.name);
      // }
    }

    return <StyledTableCell key={idx} style={{fontSize : props.dataFontSize, width: '20rem'}}>{values.slice(0,2).join(", ")}&nbsp;
    {values.length > 2 &&
      <span className={css.MoreItems}>
      +{values.slice(2).length}
      <ul className={css.ListDropdown}>
          {values.slice(2).map((item, idx) =>{
          return( 
          <li key={idx}>{item}</li>)})}
      </ul>    
      </span>}
      </StyledTableCell>
  }

  return (
    <Fragment>
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {props.columns.map((columnName, idx) => 
            <StyledTableCell key={idx} style={{fontSize : props.headersFontSize,fontWeight: '550'}}>{columnName}</StyledTableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data && props.data.length!==0 ? props.data.map((object) => (
            <TableRow key={object.id}>
              {props.fieldsTypes.map((fieldType, idx) => {
                const field = Object.keys(fieldType)[0];
                const type = fieldType[field]; 
                if(type === 'primary')
                  return <StyledTableCell key={idx} style={{fontSize:props.dataFontSize ,color:'black',fontWeight:'600'}}>{props.renderer(object, field)}</StyledTableCell>
                else if(type === 'checkbox')
                  return <StyledTableCell key={idx} style={{fontSize : props.dataFontSize}} component="th" scope="row">
                            <Checkbox id={object.id} style={{ color : '#6e6b67'}} checkedIcon={<CheckBoxOutlinedIcon style={{color: "#7ea63f"}} />} onChange={(e) => props.checkboxHandler(e)} defaultChecked={object[field]}/></StyledTableCell>
                else if(type === 'list')
                  return renderList(object,field, idx);
                else if(type === 'radiobox')
                  return <StyledTableCell key={idx} style={{fontSize : props.dataFontSize}} component="th" scope="row">
                          <Checkbox id={object.id} style={{ color : '#6e6b67'}} icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon style={{color: "#7ea63f"}} />} onChange={(e) => props.checkboxHandler(e,object.id)} defaultChecked={props.renderer(object,field)}/></StyledTableCell>
                else if(type === 'date')
                  return <StyledTableCell key={idx} style={{fontSize : props.dataFontSize}} >{reformat_date_str(props.renderer(object, field))}</StyledTableCell>
                else if(type === 'dots')
                  return <StyledTableCell key={idx} style={{fontSize : props.dataFontSize}} ><IconButton disabled={props.renderer(object, field)} size="small" className={classes.iconButton} aria-controls="simple-menu" aria-haspopup="true" onClick={(clickHandler(object))}><MoreHorizIcon/></IconButton></StyledTableCell>
                else if(type === 'circle')
                  return <StyledTableCell key={idx}><Circle size={props.dataFontSize+40} value={props.renderer(object, field)} color='#7ea63f'/></StyledTableCell> 

                return <StyledTableCell key={idx} style={{fontSize : props.dataFontSize}} >{props.renderer(object, field)}</StyledTableCell>
              })}
            </TableRow>
          )): !props.hide_not_found_msg && <TableRow>
          <TableCell colSpan={props.columns.length}>
            <img style={{marginLeft:"10%"}} src={process.env.PUBLIC_URL + '/empty_table.svg'} alt=""/>
            <span style={{marginLeft:"2%",fontSize:"20px",fontWeight:"bold",color:"#54524f"}}>{props.not_found_msg ? props.not_found_msg : `Looks like you haven't added ${props.objectName}s yet`}</span>
              {!props.not_found_msg && <div style={{marginLeft:"50%",transform:"translateY(-70px)"}}><ItemAddButton buttonTitle={`Add ${props.objectName}`} width={150} height={35} fontSize={13} clickHandler={props.emptyButtonHandler} backgroundColor="transparent"/></div>}
          </TableCell>
          </TableRow>}
        </TableBody>
      </Table>
    </TableContainer>
    <Menu
    id="simple-menu"
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={handleClose}>
      {props.extraMenuItems.map(item => 
        <MenuItem name={item.name} onClick={() => {
        handleClose();
        item.clickHandler(selectedObject);}}>
          <span style={{marginRight:"10px"}}><FontAwesomeIcon icon={item.icon} /></span>{item.name}</MenuItem>)}
      <MenuItem name="Edit" onClick={() => {
        handleClose();
        props.editHandler(selectedObject);}}>
          <span style={{marginRight:"10px"}}><FontAwesomeIcon icon={faEdit} /></span>Edit</MenuItem>
      <MenuItem name="Delete" onClick={() => {
        handleClose();
        setShowDeletePopup(true);
        }}><span style={{marginRight:"10px"}}><FontAwesomeIcon icon={faTrashAlt} /></span>Delete</MenuItem>
      </Menu>
    {showDeletePopup && 
    <ConfirmationDialog dialogTitle="Warning !" handleButton1={() => {props.deleteHandler(selectedObject.id);setShowDeletePopup(false);}} 
    crossHandler={() => setShowDeletePopup(false)} handleButton2={() => setShowDeletePopup(false)} open={true} text={`Are you sure you want to delete this  ${props.objectName}? This process cannot be undone`}/>}
    </Fragment>
  );
}

StyledTable.defaultProps = {
  minWidth: 70,
  maxHeight: 300,
  headersFontSize: 15,
  dataFontSize: 15,
  backgroundColor: 'white',
  extraMenuItems: [],
  hide_not_found_msg: false
};
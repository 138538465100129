import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const CheckboxComp = props => {
    const [options, setOptions] = useState([]);
    const [checkedIdxs, setCheckedIdxs] = useState([]);
    const [showOtherInput, setShowOtherInput] = useState(false);
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    const checkboxHandler = (e, index) => {
        let contains = checkedIdxs.includes(index);
        
        let newIdxs = [...checkedIdxs];
        if (contains === true) {
            newIdxs = newIdxs.filter(i => i !== index);
        } else {
            newIdxs.push(index);
        }
        setCheckedIdxs(newIdxs);
        
        console.log("props.question.options", options, newIdxs);
        newIdxs.map(i => console.log("options[i]", options[i]));
        
        if (props.saveResponse) {
            props.saveResponse(newIdxs.map(i => options[i].value).join(","));
        }

        if (index === options.length - 1) {
            setShowOtherInput(!contains);
        }
    };

    useEffect(() => {
        setOptions(props.question.options);

        console.log("props.question.options", props.question.options);

        if (props.debug) console.log(`Response received: ${props.response}`);
        if (props.response && Object.keys(props.response).length > 0 && props.question && checkedIdxs.length === 0) {
            const resp_values = props.response.split(",");
            let _selectedIdxs = [];
            for (let i = 0; i < props.question.options.length; i++) {
                if (resp_values.includes(String(props.question.options[i].value))) {
                    _selectedIdxs.push(i);
                }
            }
            if (props.debug) console.log(`Options found: ${JSON.stringify(_selectedIdxs)}`);
            
            if (_selectedIdxs.length > 0) setCheckedIdxs(_selectedIdxs);
        }
    }, [props.question, props.response]);

    return (
        <Box sx={{
            width: '100%',
            px: { xs: 2, sm: 3, md: 4 },
            py: { xs: 4, sm: 5, md: 6 },
            position: 'relative',
        }}>
            <Box sx={{ 
                position: 'relative',
                mt: 3,
                mx: 'auto',
                width: {
                    xs: 'calc(100% - 30px)', 
                    sm: 'calc(100% - 80px)', 
                    md: 'calc(100% - 100px)' 
                },
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems:{ xs: 'start', sm: 'center' },
                justifyItems:"center",
                gap:"16px",

            }}>
                {props.showOptionLabel != false && props?.question?.option_labels?.left && 
                    <Typography
                        sx={{
                            // position: 'absolute',
                            left: { xs: '-60px', sm: '-30px', md: '-35px' },
                            top: '0',
                            fontSize: { xs: '12px', sm: '13px', md: '15px' },
             
                        }}
                    >
                        {props.question.option_labels.left}
                    </Typography>
                }



                <Box sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      alignItems:"center",
                      justifyItems:"center",
                    gap: { xs: 1.5, sm: 2, md: 2.5 },
                    // padding:2
                }}>
                    {options.map((option, i) => (
                        <div key={i} style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px'
                        }}>
                            <input
                                type="checkbox"
                                disabled={props.disable}
                                name={option.value}
                                checked={checkedIdxs.includes(i)}
                                onChange={(e) => checkboxHandler(e, i)}
                                style={{
                                    width: isMobile ? '16px' : '18px',
                                    height: isMobile ? '16px' : '18px',
                                    accentColor: '#4472C4'
                                }}
                            />
                            <label
                                htmlFor={option.value}
                                style={{
                                    fontFamily: 'Lato',
                                    fontSize: isMobile ? '14px' : '16px',
                                    cursor: props.disable ? 'default' : 'pointer'
                                }}
                            >
                                {option.value}
                            </label>
                        </div>
                    ))}
                </Box>
                {props.showOptionLabel != false && props?.question?.option_labels?.right && 
                    <Typography
                        sx={{
                            // position: 'absolute',
                            right: { xs: '-40px', sm: '-35px', md: '-40px' },
                            top: '0',
                            fontSize: { xs: '12px', sm: '13px', md: '15px' },
                      
                        }}
                    >
                        {props.question.option_labels.right}
                    </Typography>
                }
                {/* {showOtherInput && (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mt: 2
                    }}>
                        <input
                            type="text"
                            disabled={props.disable}
                            placeholder="Other: how did you hear about us?"
                            style={{
                                flex: 1,
                                padding: '8px 12px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                fontFamily: 'Lato',
                                fontSize: isMobile ? '14px' : '16px'
                            }}
                        />
                        <button
                            style={{
                                padding: '8px 16px',
                                backgroundColor: '#4472C4',
                                color: 'white',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: props.disable ? 'default' : 'pointer',
                                fontFamily: 'Lato',
                                fontSize: isMobile ? '14px' : '16px',
                                opacity: props.disable ? 0.7 : 1
                            }}
                        >
                            Done
                        </button>
                    </Box>
                )} */}
            </Box>
        </Box>
    );
};

export default CheckboxComp;
import React from 'react';
import { Box, Card, CardContent, Typography, CircularProgress, useTheme,Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { 
  ListAlt as ListIcon,
  CheckCircle as CheckIcon,
  Speed as MeterIcon,
  Visibility as EyeIcon,
  Task as DoneIcon,
  BarChart as ChartIcon 
} from '@mui/icons-material';
import { commonFetchData, commonFetchDataVariant } from '../../../../commons/utility';
import { useSelector } from 'react-redux';

// Styled components
const StatsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  cursor:"pointer",
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const StatBox = styled(Box)(({ theme }) => ({
  border: '1px solid #E4E6E7',
  borderRadius: '12px',
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexBasis: 0,
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
  },
}));

const TitleContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '16px',
});

const ValueContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  justifyContent: 'flex-start',
  marginBottom: '8px',
});

const MetricValue = styled(Box)({
  display: 'flex',
  alignItems: 'flex-end',
  gap: '8px',
});

const TrendIndicator = styled(Typography)(({ trend }) => ({
  borderRadius: '16px',
  padding: '4px 8px',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '16px',
  background: trend === 'neutral' 
    ? '#E4E5E7' 
    : trend === 'positive'
    ? '#D6F5DB'
    : '#F5D9D6',
  color: trend === 'neutral' 
    ? '#60666C' 
    : trend === 'positive'
    ? '#008000'
    : '#CA3B3B',
}));

const LoadingWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '300px',
  gap: '16px',
});

const ErrorWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '300px',
  cursor: 'pointer',
  gap: '8px',
});

const STATS_CONFIG = [
  { 
    id: 'total',
    title: 'Total Survey Sent',
    icon: ListIcon,
    getValue: data => data?.response_metric?.total || 0,
    getChange: data => data?.response_metric?.total_change || '0',
    description: 'Total number of surveys sent',
    unit: 'surveys'
  },
  {
    id: 'viewed',
    title: 'Survey Viewed',
    icon: EyeIcon,
    getValue: data => data?.response_metric?.viewed || 0,
    getChange: data => data?.response_metric?.viewed_change || '0',
    description: 'Number of times surveys were viewed',
    unit: 'views'
  },
  {
    id: 'attempted',
    title: 'Attempted Survey',
    icon: DoneIcon,
    getValue: data => data?.response_metric?.attempted || 0,
    getChange: data => data?.response_metric?.attempted_change || '0',
    description: 'Number of surveys started',
    unit: 'attempts'
  },
  {
    id: 'completed',
    title: 'Completed Survey',
    icon: CheckIcon,
    getValue: data => data?.response_metric?.completed || 0,
    getChange: data => data?.response_metric?.completed_change || '0',
    description: 'Number of surveys completed',
    unit: 'surveys'
  },
  {
    id: 'completion',
    title: 'Completion Rate',
    icon: MeterIcon,
    getValue: data => data?.response_metric?.completed_percent || 0,
    getChange: data => data?.response_metric?.completed_percent_change || '0',
    description: 'Percentage of surveys completed',
    unit: '%'
  },
  {
    id: 'dropout',
    title: 'Dropout Rate',
    icon: ChartIcon,
    getValue: data => data?.response_metric?.dropout_percent || 0,
    getChange: data => data?.response_metric?.dropout_percent_change || '0',
    description: 'Percentage of users who abandoned the survey',
    unit: '%'
  }
];

const StatItem = React.memo(({ title, icon: Icon, value, change, description, unit }) => {
  const getTrendType = (changeValue) => {
    if (changeValue === '0') return 'neutral';
    return changeValue.startsWith('-') ? 'negative' : 'positive';
  };

  const formatChange = (changeValue) => {
    if (changeValue === '0') return '0';
    return `${changeValue.startsWith('-') ? '' : '+'}${changeValue}%`;
  };

  return (
    <StatBox>
      <TitleContainer>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Icon sx={{ color: '#60666C' }} />
          <Typography 
            sx={{ 
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '24px',
            }}
          >
            {title}
          </Typography>
        </Box>
        {/* <MoreHorizIcon fontSize="small" sx={{ color: '#60666C' }} /> */}
      </TitleContainer>

      <ValueContainer>
        <MetricValue>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '40px',
              lineHeight: '48px',
            }}
          >
            {value}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '28px',
              lineHeight: '40px',
              color: '#6D7378',
            }}
          >
            {unit}
          </Typography>
        </MetricValue>
        {/* <TrendIndicator trend={getTrendType(change)}>
          {formatChange(change)}
        </TrendIndicator> */}
      </ValueContainer>

      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '18px',
          color: '#60666C',
        }}
      >
        {description}
      </Typography>
    </StatBox>
  );
});

const SkeletonStatItem = () => {
  const theme = useTheme();
  return (
    <StatBox>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Skeleton variant="circular" width={24} height={24} />
          <Skeleton variant="text" width={100} height={24} />
        </Box>
      </Box>
      
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Skeleton variant="text" width={120} height={48} />
        <Skeleton variant="text" width={40} height={32} sx={{ ml: 1 }} />
      </Box>
      
      <Skeleton variant="text" width={180} height={18} />
    </StatBox>
  );
};

const LoadingSkeletonWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
  },
}));

const ActiveSurvey = ({ height, width }) => {
  const [state, setState] = React.useState({
    loading: true,
    error: false,
    data: null,
    httpCode: 200
  });

  const theme = useTheme();
  const selectedHotel = useSelector(
    (state) => state.filter.selectedHotel || {}
  );

  const fetchData = React.useCallback(async () => {
    try {
      setState(prev => ({ ...prev, loading: true, error: false }));
      const response = await commonFetchDataVariant(`/survey/response-analytics/stats/?hotel=${selectedHotel?.hotel_code}`, {
        onErrorData: {},
        req: 'get'
      });
      setState({
        loading: false,
        error: false,
        data: response,
        httpCode: response.status
      });
    } catch (error) {
      console.error('Error fetching survey data:', error);
      setState(prev => ({
        ...prev,
        loading: false,
        error: true
      }));
    }
  }, [selectedHotel]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData,selectedHotel]);

  if (state.loading) {
    return (
      <Card sx={{ height, width }}>
        <CardContent>
          <Skeleton variant="text" width={200} height={40} sx={{ mb: 2 }} />
          <Skeleton variant="text" width={300} height={24} sx={{ mb: 3 }} />
          <LoadingSkeletonWrapper>
            {[...Array(6)].map((_, index) => (
              <SkeletonStatItem key={index} />
            ))}
          </LoadingSkeletonWrapper>
        </CardContent>
      </Card>
    );
  }

  if (state.error) {
    return (
      <ErrorWrapper onClick={fetchData}>
        <Typography color="error" variant="h6">
          Failed to load data
        </Typography>
        <Typography color="text.secondary">
          Click to retry
        </Typography>
      </ErrorWrapper>
    );
  }

  if (state.httpCode === 204 || !state.data) {
    return (
      <LoadingWrapper>
        <Typography variant="h6" color="text.secondary">
          No data found
        </Typography>
        <Typography color="text.secondary">
          Please try changing hotels/filters
        </Typography>
      </LoadingWrapper>
    );
  }

  return (
    <Card sx={{ height, width }}>
      <CardContent>
        <Typography 
          variant="h5" 
          gutterBottom
          sx={{ fontWeight: 600 }}
        >
          Currently Active Survey
        </Typography>
        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ mb: 3 }}
        >
          This section shows the response number of the guests in respect to time
        </Typography>
        <StatsContainer>
          {STATS_CONFIG.map(({ id, title, icon, getValue, getChange, description, unit }) => (
            <StatItem
              key={id}
              title={title}
              icon={icon}
              value={getValue(state.data)}
              change={getChange(state.data)}
              description={description}
              unit={unit}
            />
          ))}
        </StatsContainer>
      </CardContent>
    </Card>
  );
};

export default ActiveSurvey;
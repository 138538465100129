import React, { useEffect } from 'react';
import { Box, TextField, Typography, Select, MenuItem, IconButton, InputAdornment } from '@mui/material';
import { CheckCircleOutline, Edit as EditIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '100%',
  marginBottom:"24px"
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '24px 0',
  gap: '24px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  width: '100%'
}));

const NameField = styled(Box)(({ theme }) => ({
  flex: '0 0 300px',
  '& .MuiOutlinedInput-root': {
    fontSize: '1.25rem',
    fontWeight: 500
  }
}));

const LanguageSelector = styled(Box)(({ theme }) => ({
  flex: '1',
  maxWidth: '300px'
}));

const LabelContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  marginBottom: '8px'
});

const EditableTextField = ({ value, onChange, isMultiline = false, label, minRows = 1 }) => {
    const [isEditing, setIsEditing] = React.useState(false);
    const [tempValue, setTempValue] = React.useState(value || '');
  
    const handleSubmit = () => {
      if (tempValue !== value) {
        onChange(tempValue);
      }
      setIsEditing(false);
    };
  
    return (
      <Box sx={{ width: '100%' }}>
        <LabelContainer>
          <Typography
            sx={{
              fontSize: '1.2rem',
              fontWeight: 500,
              color: '#333',
            }}
          >
            {label}
          </Typography>
          {!isEditing && (
            <IconButton 
              size="small" 
              onClick={() => setIsEditing(true)}
              sx={{ 
                color: 'gray',
                padding: '4px',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </LabelContainer>
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'flex-start',
            borderBottom: '1px solid #D8D8D8',
            p: '8px 0',
          }}
        >
          {isEditing ? (
            <TextField
              fullWidth
              value={tempValue}
              onChange={(e) => setTempValue(e.target.value)}
            //   onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleSubmit()}
            // onBlur={(e)=>{
            //     console.log(e);
                
            //     e.stopPropagation()
            //     setIsEditing(false)
            // }}
              autoFocus
              variant="outlined"
              size="small"
              multiline={isMultiline}
              minRows={minRows}
              maxRows={isMultiline ? 5 : 1}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSubmit} size="small">
                      <CheckCircleOutline />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          ) : (
            <Typography 
              sx={{ 
                textAlign:"left",
                // flex: 1,
                whiteSpace: 'pre-wrap',
                // ...(value ? { minHeight: isMultiline ? 'auto' : 'auto' } : {})
              }}
            >
              {value}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

const SurveyInfoHeader = ({ 
  survey, 
  onNameChange,
  onInternalNoteChange,
  onWelcomeMessageChange,
  onLanguageChange 
}) => {

  return (
    <StyledSection>
      <StyledHeader>
        <NameField>
          <EditableTextField
            value={survey?.name}
            onChange={onNameChange}
            label="Survey Name"
          />
        </NameField>

        <LanguageSelector>
          <Typography sx={{ fontSize: '1.2rem', fontWeight: 500, color: '#333', mb: 1 }}>
            Language
          </Typography>
          <Select
            fullWidth
            size="small"
            value={survey?.language?.code || ''}
            onChange={(e) =>{
                e.stopPropagation()
                let lang = survey.survey_languages.find(k=>k.code === e.target.value)
                if(lang) onLanguageChange(lang.survey_id)
            }}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Language
            </MenuItem>
            {survey?.survey_languages?.map((lang) => (
              <MenuItem key={lang.code} value={lang.code}>
                {lang.label}
              </MenuItem>
            ))}
          </Select>
        </LanguageSelector>
      </StyledHeader>

      {/* <EditableTextField
        value={survey?.welcome_msg}
        onChange={onWelcomeMessageChange}
        isMultiline={true}
        label="Welcome Message"
        minRows={2}
      /> */}

      <EditableTextField
        value={survey?.internal_note}
        onChange={onInternalNoteChange}
        isMultiline={true}
        label="Internal Note"
        minRows={2}
      />


    </StyledSection>
  );
};

export default SurveyInfoHeader;
import React, { useEffect, useState, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

// MUI Imports
import { 
  IconButton, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  DialogActions, 
  Button 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

// Local Component Imports
import EditableField from '../../../../../components/UI/editableField';
import ItemAddButton from '../../../../../components/UI/buttons/itemAddButton';
import QuestionComponent from './QuestionComponent';
import NewQuestion from './NewQuestion';

// Actions
import { 
  setCreateSurvey, 
  getSurvey, 
  createNewSection, 
  createNewSurvey, 
  deleteSection, 
  editSectionName, 
  editSurvey 
} from '../../../../../actions/surveyActions';

// Styles
import css from './QuestionRenderer.module.css';
import { add_auth_header } from '../../../../../components/Utility/Utility';

const SectionRenderer = (props) => {
  const [surveyId, setSurveyId] = useState(null);
  const [section, setSection] = useState({});
  const [questions, setQuestions] = useState([]);
  const [index, setIndex] = useState(null);
  const [sectionDelPopup, setSectionDelPopup] = useState({ show: false, id: null });

  const dispatch = useDispatch()

  useEffect(() => {
    setSurveyId(props.surveyId);
    setSection(props.section);
    setQuestions(props.section.questions);
    setIndex(props.index);
  }, [props.surveyId, props.section, props.index]);

  const deleteSectionHandler = (id) => {
    if (!id) return;

    props.deleteSection(
    dispatch( add_auth_header({})), 
      surveyId, 
      id, 
      (status) => {
        if (status) {
          setSectionDelPopup({ show: false, id: null });
          if (props.handleSectionDeleteFromSurvey) {
            props.handleSectionDeleteFromSurvey(id);
          }
        }
      }
    );
  };

  const handleQuestionDeleteFromSection = (id) => {
    const _questions = questions.filter((q) => q.id !== id);
    setQuestions(_questions);
  };

  const handleQuestionAddToSection = (question_obj) => {
    const _questions = [...questions, question_obj];
    _questions.sort((q1, q2) => (q1.sort_order < q2.sort_order ? -1 : 1));
    setQuestions(_questions);
  };

  return (
    <Fragment>
      <div 
        className={css.EditTemplatesSection} 
        style={props.EditTemplatesSection || {}}
      >
        <div 
          className={css.EditTemplatesSectionHead} 
          style={props.EditTemplatesSectionHead || {}}
        >
          {index}. 
          &nbsp;
          <EditableField 
            className={css.SectionHeadNameInput}
            value={section.name || `Section ${index + 1}`}
            placeholder="Section Name"
            iconColor=""
            inputWidth="60%"
            onChange={(value) => 
              props.editSectionName(
              dispatch(  add_auth_header({})), 
                surveyId, 
                section.id, 
                value, 
                () => {}
              )
            }
          />
          <div className={css.SectionHeadButtons}>
            <IconButton 
              size="small" 
              onClick={() => setSectionDelPopup({ show: true, id: section.id })}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
        
        {questions.map((question, questionIndex) => (
          <QuestionComponent 
            debug={false}
            key={questionIndex + 1}
            index={questionIndex + 1}
            surveyId={surveyId}
            sectionId={section.id}
            question={question}
            handleQuestionDeleteFromSection={handleQuestionDeleteFromSection}
            showOptionLabel={props.showOptionLabel}
          />
        ))}
        
        <NewQuestion 
          index={questions.length + 1}
          surveyId={surveyId}
          sectionId={section.id}
          handleQuestionDeleteFromSection={handleQuestionDeleteFromSection}
          updateMainQuestionData={setQuestions}
          showOptionLabel={props.showOptionLabel}
        />
      </div>

      <Dialog
        open={sectionDelPopup.show}
        onClose={() => setSectionDelPopup({ show: false, id: null })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this section? This process cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setSectionDelPopup({ show: false, id: null })} 
            color="primary"
          >
            No
          </Button>
          <Button 
            onClick={() => deleteSectionHandler(sectionDelPopup.id)} 
            color="primary" 
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
//   add_auth_header: bindActionCreators(add_auth_header, dispatch),
  editSurvey: bindActionCreators(editSurvey, dispatch),
  editSectionName: bindActionCreators(editSectionName, dispatch),
  deleteSection: bindActionCreators(deleteSection, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SectionRenderer);
import React from 'react';
import { Box, Card, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import success from "../assets/section-success.png";

const SectionSuccess = ({ completedSectionName, nextSection, next }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        backgroundColor: '#F9FAFA',
        minHeight: '90vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2
      }}
    >
      <Card
        sx={{
          width: isMobile ? '100%' : '440px',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          boxShadow: 3,
          borderRadius: 3
        }}
      >
        <img
          src={success}
          alt="Section Completed"
          style={{
            width: isMobile ? '150px' : '200px',
            height: 'auto',
            marginBottom: '16px'
          }}
        />
        <Typography
          variant="h5"
          align="center"
          sx={{ fontSize: isMobile ? '1.25rem' : '1.5rem', fontWeight: 'bold' }}
        >
          {completedSectionName} Completed
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{ fontSize: isMobile ? '0.875rem' : '1rem', color: '#555' }}
        >
          Let's move to {nextSection}. Click the Next button to proceed.
        </Typography>
        <Button
          onClick={next}
          variant="contained"
          sx={{
            backgroundColor: '#E4E6E7',
            color: '#000',
            width: isMobile ? '80%' : '110px',
            mt: 2,
            '&:hover': {
              backgroundColor: '#d3d5d6'
            }
          }}
        >
          Next
        </Button>
      </Card>
    </Box>
  );
};

export default SectionSuccess;

import { green } from "@mui/material/colors";
import { format, startOfDay } from "date-fns";
import AddIcCallRoundedIcon from '@mui/icons-material/AddIcCallRounded';
import axiosClient from "../httpClient";
import store from "../store";

const MILLISECOND_PER_DAY = 1000 * 60 * 60 * 24;

export function getLocalTime(utcDateTime) {
  if (!utcDateTime) return "";

  try {
    return new Date(utcDateTime).toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "numeric",
    });
  } catch (err) {
    console.log(`Error in parsing time. ${utcDateTime}`);
    return "";
  }
}

export function getFriendlyDate(utcDateTime) {
  if (!utcDateTime) return "";

  try {
    var todays_millis = startOfDay(new Date()).getTime();
    var msg_dt = new Date(utcDateTime);
    var msg_millis = msg_dt.getTime();
    var days_diff = (todays_millis - msg_millis) / MILLISECOND_PER_DAY;

    if (msg_millis > todays_millis) return "Today";
    else if (days_diff <= 1) return "Yesterday";
    else if (1 < days_diff && days_diff <= 6)
      return msg_dt.toLocaleString(undefined, { weekday: "long" });
    else return msg_dt.toLocaleDateString();
  } catch (err) {
    console.log(`Error in parsing time. ${utcDateTime}`);
    return "";
  }
}

export function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name, others) {
  if (name) {
    if (name.split(" ").length == 1 && name.split(" ")[0][0] === "+") {
      return {
        sx: { bgcolor: green[500], ...others },
        children: <AddIcCallRoundedIcon />,
       
      };
    }
    else {
      return {
        sx: {
          bgcolor: stringToColor(name),
          ...others,
        },
        children: `${name.split(" ")[0][0] ? name.split(" ")[0][0] : ""}${name.split(" ")[1] ? name.split(" ")[1][0] : ""
          }`,
      };
    }
  } else {
    return {
      sx: {
        // bgcolor: stringToColor(name),
        ...others,
      },
      children: ""
    }
  }
}


export function getUrlPreviewData(url) {
  return new Promise((resolve, reject) => {
    const apiKey = 'pk_db60c4596f6bae241940fe09b4759f2f5057991d';
    let resp = extractUrlsFromString(url)
    console.log('respons of extractUsr', resp);
    if (resp.success) {
      const apiUrl = `https://jsonlink.io/api/extract?url=${resp.url}&api_key=${apiKey}`;
      console.log("fetingfo r ", resp.url);
      fetch(apiUrl)
        .then(response => {
          return response.json();
        })
        .then(data => {
          console.log('url data', data);
          if (Object.keys(data).length > 0 && !data.error)
            resolve({
              success: true,
              data,
              status: "1"
            })
          resolve({
            success: true,
            data,
            status: "2"
          })
        })
        .catch(error => {
          console.error('An error occurred:', error);
          resolve({
            success: true,
            data: {},
            status: "2"
          })
        });
    } else {
      resolve({
        success: false,
        status: "0"
      })
    }
  })
}

export function extractUrlsFromString(inputString) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const urls = inputString.match(urlRegex) || [];
  const stringWithoutUrls = inputString.replace(urlRegex, '').trim();
  const resultArray = urls.map(url => ({ url, string: stringWithoutUrls }));
  if (resultArray.length > 0)
    return {
      success: true,
      url: resultArray[0].url.trim(),
      str: resultArray[0].string
    }
  return {
    success: false
  };
}

export function containsUrl(inputString) {
  const urlRegex = /((https?:\/\/)|(www\.))([^\s]+)/g;
  return urlRegex.test(inputString);
}

export const setDynamicPageTitle = (title) => {
  document.title = title;
};

function convertUtcToLocal(utcDateString, userTimeZone, userLocale) {
  const localTime = new Date(utcDateString);
  return localTime.toLocaleString('en-US', { timeZone: userTimeZone });
}

export const formatDate = (inputDate, hourFormat = "12h") => {
  const userLocale = 'en-US';
  // navigator.language ||
  const currentDate = new Date();
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const messageDate = new Date(convertUtcToLocal(inputDate, userTimeZone, userLocale));

  if (!isNaN(messageDate)) {
    const isCurrentWeek =
      currentDate.getFullYear() === messageDate.getFullYear() &&
      currentDate.getMonth() === messageDate.getMonth() &&
      currentDate.getDate() - messageDate.getDate() <= 6 &&
      currentDate.getDay() >= messageDate.getDay();

    const timeOptions = {
      hour: 'numeric',
      minute: 'numeric',
      // hour12: hourFormat!=="12h",
    };

    if (isCurrentWeek) {
      // Format for current week
      if (currentDate.getDate() === messageDate.getDate()) {
        return `Today at ${messageDate.toLocaleTimeString(userLocale, timeOptions)}`;
      } else if (currentDate.getDate() - messageDate.getDate() === 1) {
        return `Yesterday at ${messageDate.toLocaleTimeString(userLocale, timeOptions)}`;
      } else {
        return `${getDayOfWeek(messageDate.getDay())} at ${messageDate.toLocaleTimeString(userLocale, timeOptions)}`;
      }
    } else {
      // Format for previous weeks
      return `${getMonthName(messageDate.getMonth())} ${messageDate.getDate()} at ${messageDate.toLocaleTimeString(userLocale, timeOptions)}`;
    }
  } else {
    return 'Invalid Date';
  }
}

function getMonthName(monthIndex) {
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  return monthNames[monthIndex];
}

function getDayOfWeek(dayIndex) {
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return dayNames[dayIndex];
}


export const fetchSuperAdminList = async () => {
  try {
    const resp = await axiosClient.get(`/accounts/super-admin/accounts/`)
    if (resp.status === 200)
      return resp.data
    else
      return []
  } catch (error) {
    console.log(error)
    return []
  }
}

export const fetchCreateCampaignData = async (hotel) => {
  try {
    const resp = await axiosClient.get(`/messages/campaigns/form/?hotel=${hotel}`)
    if (resp.status === 200)
      return resp.data
    else
      return []
  } catch (error) {
    console.log(error)
    return []
  }
}

export const fetchStaffData = async (hotel) => {
  try {
    const resp = await axiosClient.get(`/accounts/registration/form/?hotel=${hotel}`)
    if (resp.status === 200)
      return resp.data
    else
      return []
  } catch (error) {
    console.log(error)
    return []
  }
}

export const fetchBroadCastForm = async (hotel) => {
  try {
    const resp = await axiosClient.get(`/messages/broadcasts/form/?hotel=${hotel}`)
    if (resp.status === 200)
      return resp.data
    else
      return {}
  } catch (error) {
    console.log(error)
    return {}
  }
}

export const fetchPlace_EventForm = async (hotel) => {
  try {
    const resp = await axiosClient.get(`/data-aggregator/places-events/form/?hotel=${hotel}`)
    if (resp.status === 200)
      return resp.data
    else
      return {}
  } catch (error) {
    console.log(error)
    return {}
  }
}

export const formatForCampaignTimming = (timeString) => {
  const regex = /<(\d+) day(?:s?) (after|before) (\d{2}:\d{2})>/;
  const match = timeString.match(regex);

  if (match) {
    const days = parseInt(match[1]);
    const direction = match[2];
    const time = match[3];
    const dayString = [1, 0].includes(days) ? `${days} day` : `${days} days`;
    const directionString = direction === "after" ? "after" : "before";
    return `${dayString} ${directionString} at ${time}`;
  }

  return "--";
}

export const trimReview = (inputStr) => {
  const parts = inputStr.split(/\(Original\): |\(Translated\): /);
  const result = {};

  for (let i = 1; i < parts.length; i += 2) {
    result[`line${Math.ceil((i + 1) / 2)}`] = parts[i].trim();
  }
  // console.log(result,inputStr)
  if (Object.keys(result).length > 0)
    return `${result?.line1}\n${result?.line2}`
  else {
    if (inputStr.startsWith('\n')) {
      inputStr = inputStr.replace(/^\n+/, '');
    }
    return inputStr
  }
}

export const generateContentForCampaign = async (body, hotel) => {
  try {
    const resp = await axiosClient.post(`/messages/campaigns/message-generation/?hotel=${hotel}`, body)
    return {
      status: true,
      data: resp.data
    }

  } catch (error) {
    console.log(error)
    return {
      status: false,
      data: Array.isArray(error?.response?.data) ? error?.response?.data[0] : "something went wrong"
    }
  }
}

export const getMessageForWhatsappChannel = async (userid, hotel) => {
  try {
    const resp = await axiosClient.get(`/innspire-ai/chat/whatsapp/templates/?hotel=${hotel}&user_id=${userid}`)
    if(resp.status>=200 && resp.status<210)  
    return {
      status: true,
      data: resp.data
    }
    else
    return{
  status:false,
  data:"something went wrongpp"
  }

  } catch (error) {
    console.log(error)
    return {
      status: false,
      data: Array.isArray(error?.response?.data) ? error?.response?.data[0] : "something went wrong"
    }
  }
}

export const convertDateToLocale = (data) => {
  if(!data) return data
  try {
 if(Array.isArray(data) && data.length>0){
  if(data[0]?.date){
    let modifiedData = data.map((e)=>{
      return {
        ...e,
        date:e.date?new Date(e.date).toLocaleDateString():"no date"
      }
    })
    return modifiedData
  }

 }else if (typeof data === "object"){
  if(data?.date)
    return{
  ...data,
  date:data.date.toLocaleDateString()
    }

 }else
 return data
  } catch (error) {
    console.log(error)
    return data
  }
}

export const fetchChatAnalyticsForm = async (hotel) => {
  try {
    const resp = await axiosClient.get(`/chat-analytics/form/?hotel=${hotel}`)
    if (resp.status === 200)
      return resp.data
    else
      return {}
  } catch (error) {
    console.log(error)
    return {}
  }
}

export const getCurrentDateAndTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const sortMessagesByCreatedAt = (messages) => {
  console.log("sorting performed")
  return messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
};
export const extractPageNumber = (url) => {
  const urlParams = new URLSearchParams(url.split('?')[1]);
  return urlParams.get('page') ? parseInt(urlParams.get('page'), 10) : 1;
};

export const formatDateTime = (date) => {
  try {
   
  if(!date)
    return null
  return date.toISOString().slice(0, -8);
  } catch (error) {
    console.log(error,date)
    
  }
};

export const getDeviceInfo = () => {
try {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const deviceInfo = {
    userAgent: userAgent,
    platform: platform,
    language: navigator.language,
    appVersion: navigator.appVersion,
    vendor: navigator.vendor
  };
  return deviceInfo;
} catch (error) {
  console.log(error)
  return null
}
};

export const getGroupGuestCount = async (hotel,filterObj) => {
  try {

    let str = ""
 
    for (let [key, value] of Object.entries(filterObj)) {
      console.log(key, value);
      str += `&${key}=${value}`
  }

    const resp = await axiosClient.get(`/innspire-ai/v2/guests/?hotel=${hotel}${str}`)
    if (resp.status === 200)
      return resp.data
    else
      return null
  } catch (error) {
    console.log(error)
    return null
  }
}

const detectPlatform = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  if (/Mac/i.test(userAgent)) {
    return "MacOS";
  }

  if (/Win/i.test(userAgent)) {
    return "Windows";
  }

  if (/Linux/i.test(userAgent)) {
    return "Linux";
  }

  return "unknown";
};


export const saveStaffToken = async (token,device_id,hotel_code) => {
  try {
    const body = {
      "token": token,
      "device_id": device_id,
      "device_type": detectPlatform()
    }
    const resp = await axiosClient.post(`/messages/notifications/firebase-tokens/?hotel=${hotel_code}`,body)
    if (resp.status === 200)
      return true
    else
      return false
  } catch (error) {
    console.log(error)
    return false
  }
}

export const deepClone = (obj)=> {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(deepClone);
  }

  const clonedObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      clonedObj[key] = deepClone(obj[key]);
    }
  }

  return clonedObj;
}


export const fetchMessageTemplate = async (hotel,filterObj) => {
  try {

    let str = ""
 
    for (let [key, value] of Object.entries(filterObj)) {
      console.log(key, value);
      str += `&${key}=${value}`
  }

    const resp = await axiosClient.get(`/messages/msg-templates/?hotel=${hotel}${str}`)
    if (resp.status === 200)
      return resp.data
    else
      return null
  } catch (error) {
    console.log(error)
    return null
  }
}


export const fetchMessageTempateForm = async (hotel) => {
  try {
    const resp = await axiosClient.get(`/messages/msg-templates/form/?hotel=${hotel}`)
    if (resp.status === 200)
      return resp.data
    else
      return {}
  } catch (error) {
    console.log(error)
    return {}
  }
}

export const ensureHttps = (url)=> {
  if (url.startsWith('http://')) {
    return url.replace('http://', 'https://');
  }
  return url;
}

export const commonFetchData = async (url,parameter = {
  onErrorData: {}
}) => {
let {onErrorData,req,body, setLoading=null} =  parameter
console.log("excuting......................");

  try {
    const resp = await axiosClient[req](`${url}`,body?body:{})
    if (resp.status === 200){
      
      if(setLoading)
        setLoading(false)
      return resp.data
    }
  
    else{
      if(setLoading)
        setLoading(false)
      return onErrorData
    }

  } catch (error) {
    console.log(error)
    if(setLoading)
      setLoading(false)
    return onErrorData
  }
}


export const commonFetchDataVariant = async (url,parameter = {
  onErrorData: {}
}) => {
let {onErrorData,req,body, setLoading=null} =  parameter
console.log("excuting......................");

  try {
    const resp = await axiosClient[req](`${url}`,body?body:{})
    if (resp.status === 200){
      
      if(setLoading)
        setLoading(false)
      return {
        ...resp.data,
        status:resp.status
      }
    }
  
    else{
      if(setLoading)
        setLoading(false)
      return onErrorData
    }

  } catch (error) {
    console.log(error)
    if(setLoading)
      setLoading(false)
    return onErrorData
  }
}

export const addFilterKey = (data=[],url,startWith="&",Format="MM-dd-yyyy") =>{
try {
  let arr = data.map((e)=>{
    if(e.filterType && e.filterType=="dateRange"){
      let startDate = e.query_value.startDate
      let endDate = e.query_value.endDate
      if (startDate == null) startDate = new Date();
      if (endDate == null) endDate = new Date();
  
      if (typeof startDate == "string") {
        startDate = new Date(startDate);
        endDate = new Date(endDate);
      }
      return `${e.query_key}=${format(startDate, Format)} to ${format(
        endDate,
        Format
      )}`;
    }
    else {
      return `${e.query_key}=${e.query_value}`
    }
  })
  return url + (arr.length>0 ?startWith:"") + arr.join("&")
} catch (error) {
  console.log(error)
  return url
}
}


export const addSortParams = (sortObj={},url,startWith="&",Format="MM-dd-yyyy") =>{
  try {
    // if(Object.keys(sortObj).length<1)
    //   return url
    const sortArray = [];

    for (const [key, order] of Object.entries(sortObj)) {
      if (key.includes(',')) {
        const keys = key.split(',');
        keys.forEach((k) => {
          sortArray.push(order === 'asc' ? k : `-${k}`);
        });
      } else {
        sortArray.push(order === 'asc' ? key : `-${key}`);
      }
    }
  console.log(sortArray.join(","));
  
    return `${url}${(sortArray.length>0 ?startWith:"")}order_by=${sortArray.join(",")}`;
  } catch (error) {
    console.log(error)
    return url
  }
  } 

export const clearUrlParams = () => {
  const currentPath = window.location.pathname;
  window.history.pushState({}, '', currentPath);
};


export const processFilterValues = (filter) => {
  const { filter_values } = filter;

  if (typeof filter_values === 'boolean') {
    return filter_values ? 'Yes' : 'No';
  }

  if (typeof filter_values === 'string') {
    return filter_values;
  }

  if (Array.isArray(filter_values)) {
    return filter_values.join(', ');
  }

  if (typeof filter_values === 'object') {
    return Object.entries(filter_values)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return `${key}: ${value.join(', ')}`;
        }
        return `${key}: ${value}`;
      })
      .join(', ');
  }

  return 'N/A'; 

}


export function modifyData(data, operation, obj = null, id = null) {

  switch (operation) {
      case 'post':
          if (obj) {
              data.unshift(obj);
          }
          break;

      case 'patch':
          const updateIndex = data.findIndex(item => item.id === id);
          if (updateIndex !== -1 && obj) {
              data[updateIndex] = { ...data[updateIndex], ...obj };
          }
          break;

      case 'delete':
          const deleteIndex = data.findIndex(item => item.id === id);
          if (deleteIndex !== -1) {
              data.splice(deleteIndex, 1);
          }
          break;

      default:
          console.error('Invalid operation');
  }
  return [...data];
}


export const pushGAEvent = (data)=>{
  const username = store.getState().auth?.user?.first_name
  const selectedHotel = store.getState().filter?.selectedHotel?.hotel_code
  data.user_id = username
  data.hotel_code = selectedHotel
  console.log("Pushing ga for",data)
  let dataLayer = window.dataLayer || [];
  dataLayer.push(data);
  window.dataLayer = dataLayer;
}


export const compareObjects = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};


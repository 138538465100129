import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const RadioBtn = props => {
    const [options, setOptions] = useState([]);
    const [checkedIdx, setCheckedIdx] = useState(null);

    useEffect(() => {
        setOptions(props.question.options);
        
        if (props.debug) console.log(`Response received: ${props.response}`);
        
        if (props.response && props.question && checkedIdx === null) {
            const optionIndex = props.question.options.findIndex(
                o => String(o.value) === String(props.response)
            );
            
            if (props.debug) console.log(`Option found: ${optionIndex}`);
            
            if (optionIndex !== -1) setCheckedIdx(optionIndex);
        }
    }, [props.question, props.response, checkedIdx]);

    const checkHandler = (e, index) => {
        setCheckedIdx(index);
        if (props.saveResponse) props.saveResponse(options[index].value);
    };

    return (
        <div className="relative flex items-center justify-center w-full py-4">
            {/* Left Label */}
            {props.showOptionLabel != false && props?.question?.option_labels?.left && 
                    <Typography
                        sx={{
                            // position: 'absolute',
                            left: { xs: '-60px', sm: '-30px', md: '-35px' },
                            top: '0',
                            fontSize: { xs: '12px', sm: '13px', md: '15px' },
                
                        }}
                    >
                        {props.question.option_labels.left}
                    </Typography>
                }

            {/* Radio Buttons */}
            <div className="flex flex-row gap-4">
                {options.map((option, i) => (
                    <div key={i} className="flex items-center">
                        <input
                            type="radio"
                            id={`radio-${i}`}
                            name={`radio-group-${props.question?.id || 'default'}`}
                            checked={checkedIdx === i}
                            onChange={(e) => checkHandler(e, i)}
                            className="w-4 h-4 text-blue-600 cursor-pointer"
                        />
                        <label
                            htmlFor={`radio-${i}`}
                            className="ml-2 text-sm font-medium text-gray-700 cursor-pointer"
                        >
                            {option.value}
                        </label>
                    </div>
                ))}
            </div>

            {/* Right Label */}
            {props.showOptionLabel != false && props?.question?.option_labels?.right && 
                    <Typography
                        sx={{
                            // position: 'absolute',
                            right: { xs: '-40px', sm: '-35px', md: '-40px' },
                            top: '0',
                            fontSize: { xs: '12px', sm: '13px', md: '15px' },
                     
                        }}
                    >
                        {props.question.option_labels.right}
                    </Typography>
                }
        </div>
    );
};

export default RadioBtn;
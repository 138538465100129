import React, { useState, useEffect } from 'react';
import BaseAPICompnenetWithLoader from '../../../../components/APIComponents/BaseAPIComponent/BaseAPICompnenetWithLoader';
import QuestionComponent from './OtherComponents/QuestionComponent';
import SurveyRenderer from './OtherComponents/SurveyRenderer';
import {bindActionCreators} from 'redux';
import { getSurveyPreview } from '../../../../actions/surveyActions';
import { connect } from 'react-redux';
import { url } from '../../../../components/Constants/Constants';
import {add_auth_header, get_query_param, getPlatformDetails} from '../../../../components/Utility/Utility';
import axios from 'axios';

import css from './UserSurvey.module.css';
import { Fragment } from 'react';
import EmptySurvey from './OtherComponents/EmptySurvey';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea } from '@mui/material';
import HorizontalStepperWithStepTitle from '../../../../components/UI/horizontalStepperWithStepTitle';
import { makeStyles } from '@material-ui/core';
import SurveySuccess from './OtherComponents/SurveySuccess';
import SectionSuccess from './OtherComponents/SectionSuccess';
import GuestInfoForm from './OtherComponents/GuestInfoForm';
import DeviceDetector from "device-detector-js";
import { commonFetchData } from '../../../../commons/utility';
import FullScreenLoader from '../../../../commons/components/UI/FullScreenLoader';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(({
    cardContent: {
        padding: "3% !important",
        height: "192px",
    },
    texts: {
        fontFamily: 'Nunito',
        fontStyle: 'italic',
        fontWeight: '400',
        fontSize: '18px !important',
        lineHeight: '28px',
        letterSpacing: '0.0025em',
        color: '#5C6A78',
        width: '56%',
        padding: '0px 10px',
        borderLeft: '4px solid #9AB3DA'
    },
    hotelNameContainer: {
        position: 'absolute',
        background: 'linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%)',
        mixBlendMode: 'overlay',
        width: '200px',
        height: '40px',
        marginTop: '25px'
    },
    hotelName: {
        position: 'absolute',
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '30px',
        lineHeight: '27px',
        color: '#FFFFFF',
        marginTop: '35px',
        marginLeft: '20px',
    }
}))

const Survey = (props) => {

    const classes = useStyles()

    const [api_data, setAPIData] = useState({});
    const [userResponse,setUserResponse] = useState([[]]);
    const [surveyId,setSurveyId] = useState(get_query_param('surveyId'));
    const [surveyToken,setSurveyToken] = useState(get_query_param('token'));
    const [selectedLang, setSelectedLang] = useState("")
    const [currSection, setCurrSection] = useState(0);
    const [currQuestion,setCurrQuestion] = useState(0);
    const [showSectionSuccess, setShowSectionSuccess] = useState(false)
    const [showSurveySuccess, setshowSurveySuccess] = useState(false)
    const [languages, setLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({}); // code: language code, name: label
    const [survey, setSurvey] = useState({});
    const [hotel, setHotel] = useState(null);
    const [reload, setReload] = useState(1);
    const [guestInfoSubmitting, setGuestInfoSubmitting] = useState(false)
    const platformDetails = getPlatformDetails()
    const [loading,setLoading] = useState(true)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const preview = queryParams.get("preview") || null;
    const camp_code = queryParams.get("camp_code") || null


    useEffect(()=>{
            (async()=>{
                const data = await commonFetchData(get_url(reload),{
                    req: "get",
                    onErrorData: {},
                    setLoading
                })
              if(Object.keys(data).length>0)
              {
                setAPIData(data)
              }

            })()
    },[])

    useEffect(() => {
        console.log('end',"api",api_data);
        
        if(Object.keys(api_data).length > 0){
            if (surveyToken){
                setSelectedLanguage(api_data?.language || {});
                setLanguages(api_data?.survey?.survey_languages || []);
                setSurvey(api_data.survey);
            } else {
                // set using survey object as api response
                setSurvey(api_data);
                setSelectedLanguage(api_data?.language || {});
                setLanguages(api_data?.survey?.survey_languages || []);
            }
            const deviceDetector = new DeviceDetector();
            const userAgent = navigator.userAgent
            const device = deviceDetector.parse(userAgent);

            setHotel((p)=>{    
                if(api_data?.hotel)
                    setHotel(api_data.hotel)               
                else if(api_data?.survey?.hotels && api_data.survey.hotels[0])
                    return api_data?.survey?.hotels[0]
                else
                    return {}
            });

            console.log(device);
            
        }
    }, [api_data]);

    const get_url = () => {
        let url_ = `${url}/survey`

        if (surveyToken) {
            url_=`${url_}/fill/${surveyToken}/`;
            if (selectedLanguage.code) url_=`${url_}?lang=${selectedLanguage.code}`
        }
        else {url_=`${url_}/preview/?id=${surveyId}`}

        return url_
    }

    const sectionHandler = (type) => {

        // const sectionsLength = api_data.sections.length
        const sectionLength = api_data.sections.length - 1;

        switch(type){
            case 'next':
                if(currSection === sectionLength){
                    console.log('make an api call');
                    // setCurrQuestion(currQuestion+1);
                }else{
                    setCurrSection(prev => prev + 1);
                    setCurrQuestion(0);
                }
                break;
            case 'back':
                if(currSection === 0){
                    // console.log('make an api call');
                }else{
                    setCurrSection(prev => prev - 1);
                }
                break;
            default:
                console.log('Invalid type');
        }
    }

    const userResponseHandler = (value) => {
        const arr = userResponse;
        const lastQues = survey.sections[currSection].questions.length - 1;
        const last = survey.sections[currSection].questions[lastQues];
        // if (api_data[currSection].questions[currQuestion].ques !== last.ques){
        if(value !== "none"){
            if(!arr[currSection]){
                arr.push([value]);
            }else if(arr[currSection][currQuestion]){
                arr[currSection][currQuestion] = value;
            }else {
                arr[currSection].push(value);
            }
        }else{
            arr[currSection].push(null);
        }
        setUserResponse(arr);
        console.log(arr);
        
    }

    const handleSubmit = (data) => {
        console.log("data", data)
        setGuestInfoSubmitting(true)

        let updatedData = {
            ...data,
            "device_os_name": platformDetails.os.name,
            "device_os_version": platformDetails.os.version,
            "device_browser_name": platformDetails.client.name,
            "device_browser_version": platformDetails.client.version,
            "device_platform": platformDetails.device.type
        }
        axios.patch(
            `${url}/survey/fill/${surveyToken}/`,
            updatedData
        )
        .then(res => {console.log(res);setGuestInfoSubmitting(false) 

            setAPIData(res.data)
        })
        .catch(err => {console.log(err);setGuestInfoSubmitting(false)})
    }

    const handleOnChangeLang = (data)=>{
        if(!data.language) return 
        axios.get(
            `${url}/survey/fill/${surveyToken}/?language=${data.language}`)
        .then(res => {console.log(res);setGuestInfoSubmitting(false) 

            setAPIData(res.data)
        })
        .catch(err => {console.log(err);setGuestInfoSubmitting(false)})
    }

    const startSurvey = ()=>{
        setAPIData(p=>({...p,attempted:true}))
    }


    const component = (
       !api_data?.attempted && api_data?.survey?.survey_languages?.length> 1 ?
            <GuestInfoForm handleStart={startSurvey} guestInfoSubmitting={guestInfoSubmitting} handleSubmit={handleOnChangeLang} selectedLang={selectedLanguage} setSelectedLang={setSelectedLanguage} surveyLanguages={languages} /> 
        :
        <Fragment>
            {survey.sections && survey.sections.length === 0?
            <EmptySurvey/>:
            <div style={{display: !showSectionSuccess && !showSurveySuccess && (!api_data.completed || preview) ? 'block' : 'none'}} className = {css.MainContainer}>
    <Card
    sx={{
        maxWidth: "100%",
        borderRadius: '16px',
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        backgroundColor: "#f9f9f9",
        margin: "16px auto"
    }}
>
    {/* Hotel Name Section */}
    <Box
        sx={{
            // backgroundColor: hotel ? "#3f51b5" : "#cccccc",
            // backgroundColor:"#E4E6E7",
            backgroundColor:"#E4E6E7",
            padding: "16px",
            color: "#fff",
            textAlign: "center",
        }}
    >
        <Typography
            variant="h6"
            sx={{
                fontWeight: 600,
                textTransform: "uppercase",
                letterSpacing: "1px",
                color:"black"
            }}
        >
            {hotel ? hotel.name : "HOTEL NAME"}
        </Typography>
    </Box>

   { /* Hotel Image */}
        <CardMedia
            component="img"
            sx={{
                height: { xs: 250, md: 400 }, 
                objectFit: "cover",
            }}
            image={api_data ? `${api_data.cover_photo}` : "fallback-image-url.jpg"}
            alt={hotel ? hotel.name : "Hotel"}
            // sx={{
            //     objectFit: "cover",
            // }}
        />

        {/* Card Content */}
    {/* <CardContent
        sx={{
            padding: "16px",
            textAlign: "center",
        }}
    > */}
        {/* Welcome Message */}
        {/* <Typography
    variant="body1"
    sx={{
        marginBottom: "16px",
        fontSize: "1rem",
        color: "#333",
    }}
>
    {survey.welcome_msg || (
        <>
            Welcome{" "}
            <span style={{ fontWeight: 600 }}>
                {api_data.guest_name || "GUEST NAME"}
            </span>
            , thanks for participating in the survey and helping us improve
            stay experiences.
        </>
    )}
</Typography> */}


        {/* Survey Steps */}
   {/* {
    survey?.sections &&
         <HorizontalStepperWithStepTitle
         steps={survey?.sections?.map((section, id) => section.name || `Section ${id + 1}`)}
         activeStep={currSection}
     />
   } */}
    {/* </CardContent>*/}
</Card> 
                {console.log("survey.completed",survey)}

            {/* <div className = {css.HeaderContainer}>
                <div className = {css.HotelLogo}>
                    <h5>{hotel ? hotel.name : "HOTEL NAME"}</h5>
                    {/* <span>Resorts and hotels</span> *
                </div>
                <div>
                    <h1>Hey {api_data.guest_name ? api_data.guest_name : "GUEST NAME"}, Got a couple of minutes?</h1>
                    <p>We take your feedback very seriously, 
                        we would love to hear what we could improve to have you back at {hotel ? hotel.name : "HOTEL NAME"}</p>
                    <select value={selectedLanguage.code} onChange={(event) => {setSelectedLanguage({code:event.target.value, label:event.target.label}); setReload(reload+1)}}>
                        {
                            languages.map(language => 
                                <option key={language.code} label={language.label} value={language.code}>{language.label}</option>
                            )
                        }
                    </select>
                </div>
            </div>  */}
            {/* <h4 className = {css.subHeading}>Fill the survey and earn a coupon for your next stay</h4> */}
            <SurveyRenderer 
                setCurrSection={(val) => setCurrSection(val)}
                surveyResponseObj={api_data}
                surveyToken={surveyToken}
                selectedLanguage={selectedLanguage}
                surveyId={survey.id}
                survey={survey}
                showSectionSuccess={(status) => setShowSectionSuccess(status)}
                setAPIData = {setAPIData}
                get_url = {get_url}
            />
        </div>}
        
        {console.log("survey.sections", survey.sections,showSectionSuccess && survey.sections && survey.sections.length == currSection || api_data.completed)}
        
        { showSectionSuccess && survey.sections && survey.sections.length != currSection && 
            <SectionSuccess 
                completedSectionName={`Section ${currSection}`} 
                nextSection={`Section ${currSection+1}`} 
                next={() => setShowSectionSuccess(false)} 
                back={() => setShowSectionSuccess(false)} />}

                
        { ((showSectionSuccess && survey.sections && survey.sections.length == currSection) || ( !preview && api_data.completed) ) && <SurveySuccess surveyAlreadySubmitted={api_data.completed} data={api_data}/>}

        <Typography sx={{
    padding: '20px',
    color: 'text.secondary',
    textAlign: 'center',
    fontSize: '14px'
}}>
    {survey?.hotel?.name} <br/>
    {survey?.hotel?.address}
</Typography>
        
    </Fragment>
    );

    // return (<BaseAPICompnenetWithLoader 
    //     component = {component}
    //     data_url = {get_url(reload)}
    //     // data_url = {`${url}/survey/groups/1/languages/en`}
    //     setAPIData = {setAPIData}
    //     api_dependency = {[true, reload]}
    //     not_found_msg = {'Survey not found'}
    //     hide_not_found_img = {true}
    // />);

    return <>
   {Object.keys(api_data).length> 0?component:
   loading && <FullScreenLoader/>
   }
    </>
}

const mapDispatchToProps = dispatch => {
    return{
        add_auth_header: bindActionCreators(add_auth_header, dispatch),
        getPlatformDetails: bindActionCreators(getPlatformDetails, dispatch)
        };
    };

export default connect(null,mapDispatchToProps)(Survey);
import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLoadingStatePlaceAndEvent } from "../../../commons/components/Settings/actions/settingsActions";
import { fetchAllPlaceAndEventData } from "../action/TripPlannerAction";
import CommonSingleRow from "../../../commons/components/Settings/components/CommonSingleRow";
import CommonTableUI from "../../../commons/components/Settings/components/CommonTableUI";
import { Box, Checkbox } from "@mui/material";
import SearchInput from "../commonComp/SearchInput";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const filterRoute = `/trip-planner`;

const createSelectors = () => {
  const dataSelector = (state) => 
    state.settings?.PlaceAndEvent?.Curated_List_Options?.data || [];
  const countSelector = (state) => 
    state.settings?.PlaceAndEvent?.Curated_List_Options?.count || 0;
  const pageSelector = (state) => 
    state.settings?.PlaceAndEvent?.Curated_List_Options?.page || 0;
  const loadingSelector = (state) => 
    state.settings?.PlaceAndEvent?.Curated_List_Options?.loading;
  const errorSelector = (state) => 
    state.settings?.PlaceAndEvent?.Curated_List_Options?.error;
  const selectedFiltersSelector = (state) => 
    state.filter.selectedFilters[`${filterRoute}`] || [];
  const selectedHotelSelector = (state) => 
    state.filter.selectedHotel || {};

  return {
    dataSelector,
    countSelector,
    pageSelector,
    loadingSelector,
    errorSelector,
    selectedFiltersSelector,
    selectedHotelSelector
  };
};

const OptionList = ({ idsRef, dataRef }) => {
  const [ids, setIds] = useState({});
  const APITrigger = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  
  const query = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("q") || "";
  }, [location.search]);

  const selectors = useMemo(createSelectors, []);
  const data = useSelector(selectors.dataSelector);
  const count = useSelector(selectors.countSelector);
  const page = useSelector(selectors.pageSelector);
  const selectedFilters = useSelector(selectors.selectedFiltersSelector);
  const selectedHotel = useSelector(selectors.selectedHotelSelector);

  const columns = useMemo(() => [
    { name: "select" },
    { name: "Name", orderBy: "name" },
    { name: "Location", orderBy: "location" },
    { name: "Distance", orderBy: "distance" },
    { name: "Type", orderBy: "type" },
  ], []);

  const fetchData = useCallback((newPage, q = null) => {
    const searchQuery = q === null ? query : q;
    dispatch(changeLoadingStatePlaceAndEvent(true, "Curated_List_Options"));
    dispatch(
      fetchAllPlaceAndEventData(
        newPage,
        changeLoadingStatePlaceAndEvent,
        false,
        searchQuery,
        null,
        {
          filterkey: filterRoute,
          entityType: "Curated_List_Options",
        }
      )
    );
  }, [dispatch, query]);

  const handleCheckboxChange = useCallback((id, checked) => {
    setIds(prevIds => {
      const newIds = { ...prevIds };
      if (checked) {
        newIds[id] = true;
      } else {
        delete newIds[id];
      }
      return newIds;
    });
  }, []);

  useEffect(() => {
    if (APITrigger.current) {
      clearTimeout(APITrigger.current);
    }
    
    if (Object.keys(selectedHotel).length > 0 && selectedFilters.length > 0) {
      APITrigger.current = setTimeout(() => {
        dispatch(changeLoadingStatePlaceAndEvent(true, "Curated_List_Options"));
        dispatch(
          fetchAllPlaceAndEventData(
            0,
            changeLoadingStatePlaceAndEvent,
            false,
            query || null,
            null,
            {
              filterkey: filterRoute,
              entityType: "Curated_List_Options",
            }
          )
        );
      }, 0);
    }

    return () => {
      if (APITrigger.current) {
        clearTimeout(APITrigger.current);
      }
    };
  }, [selectedFilters, selectedHotel, dispatch, query]);

  useEffect(() => {
    if (dataRef?.event_or_place_list) {
      const newIds = dataRef.event_or_place_list.reduce((acc, e) => {
        acc[e.id] = true;
        return acc;
      }, {});
      setIds(newIds);
    }
  }, [dataRef]);


  useEffect(() => {
    idsRef.current = ids;
  }, [ids, idsRef]);

  const RowComponent = useCallback(({ row }) => {
    const payload = {
      checkbox: (
        <Checkbox
          size="small"
          name={row.id}
          checked={ids[row.id] || false}
          onChange={(e) => handleCheckboxChange(row.id, e.target.checked)}
        />
      ),
      name: row.name,
      address: row.address,
      distance: row.distance,
      type: row.type,
    };

    return (
      <CommonSingleRow
        key={row?.id}
        payload={payload}
        showMenu={false}
        menuItems={[]}
        id={row.id}
        fullData={row}
      />
    );
  }, [ids, handleCheckboxChange]);

  // Memoized search handler
  const handleSearch = useCallback((q) => {
    fetchData(0, q);
  }, [fetchData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        marginBottom: 10,
      }}
    >
      <SearchInput onSearch={handleSearch} />
      <CommonTableUI
        columns={columns}
        fetchData={fetchData}
        dataSelector={selectors.dataSelector}
        loadingSelector={selectors.loadingSelector}
        errorSelector={selectors.errorSelector}
        isEditPermission={true}
        RowComponent={RowComponent}
        minRow={20}
        count={count}
        page={page}
        initialFetch={false}
        title="List"
        heading={false}
        maxHeight="1196px"
        serverSidePagination={true}
      />
    </Box>
  );
};

export default React.memo(OptionList);
import React, { useEffect, useState } from 'react'
import BasePage from '../../commons/components/BasePage/BasePage'
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import LabTabs from '../../commons/components/PageTab/LapTabs';
import PlacesTab from './PlacesTab';
import EventTab from './EventTab';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { changeLoadingStatePlaceAndEvent, fetchPlaceAndEventData } from '../../commons/components/Settings/actions/settingsActions';
import { fetchFilters } from '../../commons/components/Filter/actions/actions';

 const filterRoute = `/trip-planner`

const TripPlanner = () => {
      const [initialRenderFlag , setinitialRenderFlag] = useState(false)
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const [tab,setTab] = useState(params.get("tab") || "Events")
   const navObj = useSelector((state) => state.nav.selectedNav || {});
     const selectedHotel = useSelector(
       (state) => state.filter.selectedHotel || {}
     );
  const dispatch = useDispatch()
  const history = useHistory()

    useEffect(()=>{
      setTab(params.get("tab"))
    },[location.search])

      useEffect(() => {
        if (Object.keys(selectedHotel).length > 0) {
          dispatch(fetchFilters(`/data-aggregator/places-events/dashboard-filters/?hotel=${selectedHotel.hotel_code}`,"trip_planner",{
            isHotelChange:initialRenderFlag,
            key:`${filterRoute}`
          }))
          if(!initialRenderFlag)
            setinitialRenderFlag(true)
        }
    
      }, [selectedHotel]);

const handleFetchData = (e)=>{
  e?.stopPropagation()
  if(tab==="Events"){
              dispatch(changeLoadingStatePlaceAndEvent(true,"EVENT"))
              dispatch(fetchPlaceAndEventData(0,changeLoadingStatePlaceAndEvent,false,null,null,{
                filterkey:`${filterRoute}`,
                entityType:"EVENT"
              }));
            }
  else
  {
 dispatch(changeLoadingStatePlaceAndEvent(true,"PLACE"))
           dispatch(fetchPlaceAndEventData(0,changeLoadingStatePlaceAndEvent,false,null,null,{
             filterkey:`${filterRoute}`,
             entityType:"PLACE"
           }));
  }
}

  return (
    <BasePage title={navObj.name} showBox={true} show_side_filter={true}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          height: "100%",
          width: "100%",
          padding: "8px 0px 8px 0px",
          flexGrow: 1,
        }}
      >
        <Box
          gap={1}
          display={"flex"}
          flexDirection={"column"}
          position={"relative"}
        >
          <Typography
            variant="h3"
            component={"div"}
            sx={{
              fontWeight: "fontWeightBold",
              color: "#232531",
              fontSize: 32,
              letterSpacing: "-0.80px",
              lineHeight: "32px",
            }}
          >
            Listings
          </Typography>
          <Typography variant="body2" color={"textSecondary"}>
            Editor for the web app
          </Typography>
          {/* <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"space-between"}
              position={"relative"}
            >
         <Box
         display={"flex"}
         alignItems="center"
         bgcolor="#f6f6f6"
         px={1}
         py={0.5}
         borderRadius={1}
         >
         <LinkIcon fontSize='small' />
              <Link
              color={"inherit"}
              variant='body2'
              underline='none'
              href={process.env.REACT_APP_TRIP_PLANNER_BASE_URL}
              target="_blank"
              >
              <Typography variant="body2" marginLeft={1}>
               {process.env.REACT_APP_TRIP_PLANNER_BASE_URL}
              </Typography>
              </Link>
         </Box>
            </Box> */}

          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              // translate:"0% -50%"
            }}
            display="flex"
            gap={1}
            justifyContent="flex-end"
            marginTop={2}
          >
            <Button
              onClick={handleFetchData}
              variant="text"
              sx={{
                backgroundColor: "#eeeff1",
                borderRadius: "8px",
                height: "40px",
                padding: "0px 16px",
                "&:hover": {
                  backgroundColor: "#d6d7d9",
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "medium",
                  color: "black",
                  whiteSpace: "nowrap",
                  textTransform: "none",
                  fontSize: "14px",
                  fontWeight: "medium",
                  lineHeight: "20px",
                  letterSpacing: "-0.44px",
                }}
              >
                Fetch new {tab === "Events" ? "events" : "places"}
              </Typography>
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                history.push(
                  `/trip-planner/manage?entity=${
                    tab == "Events" ? "EVENT" : "PLACE"
                  }`
                );
              }}
              variant="text"
              color="primary"
              sx={{
                height: "40px",
                padding: "0px 16px",
                borderRadius: "8px",
                textTransform: "none",
                fontWeight: "medium",
                fontSize: "14px",
                lineHeight: "20px",
                letterSpacing: "-0.44px",
                backgroundColor: "#007bff",
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#0056b3",
                },
              }}
            >
              Add {tab === "Events" ? "an event" : "a place"}
            </Button>
          </Box>
        </Box>

        <LabTabs
          tabList={[
            {
              label: "Events",
              value: "Events",
            },
            { label: "Places", value: "Places" },
          ]}
          componentList={[
            {
              component: <PlacesTab />,
              value: "Places",
            },
            {
              component: <EventTab />,
              value: "Events",
            },
          ]}
          // tabPannelCss={{
          //   width:"100vw"
          // }}
        />
      </Box>
    </BasePage>
  );
}

export default TripPlanner



















import { Box, Typography } from "@mui/material";

export const StarIcon = ()=>{
    return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g id="lucide/star">
    <path id="Vector" d="M9.60416 1.91249C9.64068 1.83871 9.69709 1.7766 9.76704 1.73318C9.83698 1.68976 9.91767 1.66675 9.99999 1.66675C10.0823 1.66675 10.163 1.68976 10.233 1.73318C10.3029 1.7766 10.3593 1.83871 10.3958 1.91249L12.3208 5.81166C12.4476 6.0683 12.6348 6.29033 12.8663 6.4587C13.0979 6.62708 13.3668 6.73675 13.65 6.77833L17.955 7.40833C18.0366 7.42015 18.1132 7.45455 18.1762 7.50766C18.2393 7.56077 18.2862 7.63045 18.3117 7.70883C18.3372 7.78721 18.3402 7.87117 18.3205 7.95119C18.3007 8.03121 18.259 8.10412 18.2 8.16166L15.0867 11.1933C14.8813 11.3934 14.7277 11.6404 14.639 11.913C14.5503 12.1856 14.5292 12.4757 14.5775 12.7583L15.3125 17.0417C15.3269 17.1232 15.3181 17.2071 15.2871 17.2839C15.2561 17.3607 15.2041 17.4272 15.1371 17.4758C15.0701 17.5245 14.9908 17.5533 14.9082 17.5591C14.8256 17.5648 14.7431 17.5472 14.67 17.5083L10.8217 15.485C10.5681 15.3518 10.286 15.2823 9.99958 15.2823C9.71318 15.2823 9.43106 15.3518 9.17749 15.485L5.32999 17.5083C5.25694 17.547 5.17449 17.5644 5.09204 17.5585C5.00958 17.5527 4.93043 17.5238 4.86357 17.4752C4.79672 17.4266 4.74485 17.3602 4.71387 17.2835C4.68289 17.2069 4.67404 17.1231 4.68833 17.0417L5.42249 12.7592C5.47099 12.4764 5.44998 12.1862 5.36128 11.9134C5.27257 11.6406 5.11883 11.3935 4.91333 11.1933L1.79999 8.16249C1.74049 8.10502 1.69832 8.03199 1.6783 7.95172C1.65827 7.87145 1.66119 7.78717 1.68673 7.70849C1.71226 7.6298 1.75938 7.55986 1.82272 7.50665C1.88607 7.45343 1.96308 7.41908 2.04499 7.40749L6.34916 6.77833C6.63271 6.73708 6.90199 6.62754 7.13381 6.45915C7.36564 6.29076 7.55308 6.06855 7.67999 5.81166L9.60416 1.91249Z" fill="#FFBF00" stroke="#FFBF00" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    </svg>
}

export const CustomRating = (rating) => {
    return (
  rating ?     <Box
  display="flex"
  alignItems="center"
  width={64}
>
  <StarIcon
    style={{
      color: "#FFBF00",
      width: 20,
      height: 20,
    }}
  />
  <Typography
    variant="body1"
    style={{
      marginLeft: 8,
      fontWeight: 500,
      color: "#303335",
      fontSize: 14,
      letterSpacing: "-0.44px",
      lineHeight: "20px",
    }}
  >
    {rating}
  </Typography>
</Box>:""
    );
  };
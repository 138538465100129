import React from 'react'
import BasePage from '../../commons/components/BasePage/BasePage';
import FullScreenLoader from '../../commons/components/UI/FullScreenLoader';
import { Suspense } from 'react';
import { Box } from "@mui/material";
import { useSelector } from 'react-redux';
import AddFormButton from '../../commons/components/Settings/components/AddFormButton';
import SurveyTable from '../../commons/components/Settings/components/SurveyTable';
import SurveyForm from '../../commons/components/Settings/components/SurveyForm';
import ActiveSurveyNew from './pages/ManageSurvey/ActiveSurveyNew';
import SurveyResponseTable from './components/SurveyResponseTable';

const root = {
    boxShadow: "none",
    border: "none",
    display: "flex",
    gap: "32px",
    flexDirection: "column",
    height: "100%",
    padding: "20px 0",
    flexGrow: 1,
  };

const SurveyNew = () => {
    const navObj = useSelector((state) => state.nav.selectedNav || {});
  return (
    <BasePage title={navObj.name} show_side_filter={true}>
    <Suspense fallback={<FullScreenLoader height="100%" />}>
      <Box sx={root}>
      <AddFormButton title={"Add New Survey"} FormUi={<SurveyForm/>}/>
<SurveyTable/>
<ActiveSurveyNew  height="auto" 
  width="100%" />
  <SurveyResponseTable/>
      </Box>

      </Suspense>
    </BasePage>
  )
}

export default SurveyNew

import * as React from 'react';
import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { setGlobalMessage } from '../../../actions/dashboardActions';
import { useDispatch } from 'react-redux';

export default function LabTabs({ tabList = [], componentList = [], tabPannelCss = {},saveInformationOnTabChange }) {
  const disableTabRef = React.useRef({});
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const defaultTabValue = tabList.length > 0 ? tabList[0].value : '';
  const queryParams = new URLSearchParams(location.search);
  const initialTabValue = queryParams.get('tab') || defaultTabValue;

  const [value, setValue] = React.useState(initialTabValue);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // const id = queryParams.get('id');
    // if(id)queryParams.set('id', id);
    queryParams.set('tab', value);
    console.log('queryParams',queryParams.toString());
    
    history.replace({ search: queryParams.toString() });
  }, [value, history, location.search]);

  useEffect(() => {
    const newDisableTabRef = {};
    tabList.forEach((tab) => {
      if (tab.disabled) {
        newDisableTabRef[tab.value] = tab.msg;
      }
    });
    disableTabRef.current = newDisableTabRef;
  }, [tabList]);

  const handleChange = (event, newValue) => {
    event?.stopPropagation();
    if (disableTabRef.current[newValue]) {
      dispatch(
        setGlobalMessage({
          mess: disableTabRef.current[newValue],
          type: 'warning',
        })
      );
      return;
    }
    if (saveInformationOnTabChange)
    saveInformationOnTabChange(newValue)
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {tabList.map((tab, index) => (
              <Tab
                key={index}
                label={tab.label}
                value={tab.value}
                // disabled={tab.disabled}
              />
            ))}
          </TabList>
        </Box>
        {componentList.map((component, index) => (
          <TabPanel
            key={index}
            value={component.value}
            sx={{
              padding: '0px',
              ...tabPannelCss,
            }}
          >
            {component.component}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}

import React, { useState, useEffect } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

const getButtonColor = (index, totalOptions) => {
  if (totalOptions < 3) return '#FFA500'; 

  if (totalOptions === 5) {
    if (index < 2) return '#FF0000'; 
    if (index === 2) return '#FFA500'; 
    return '#00FF00'; 
  }

  const lowThreshold = Math.floor(totalOptions / 3);
  const highThreshold = Math.ceil((2 * totalOptions) / 3);

  if (index < lowThreshold) return '#FF0000'; 
  if (index >= highThreshold) return '#00FF00';
  return '#FFA500';
};


const Scale = (props) => {
  const [question, setQuestion] = useState({});
  const [options, setOptions] = useState([
    { type: "Number", value: 1, sort_order: 1 },
    { type: "Number", value: 2, sort_order: 2 },
    { type: "Number", value: 3, sort_order: 3 },
    { type: "Number", value: 4, sort_order: 4 },
    { type: "Number", value: 5, sort_order: 5 },
    { type: "Number", value: 6, sort_order: 6 },
    { type: "Number", value: 7, sort_order: 7 },
    { type: "Number", value: 8, sort_order: 8 },
    { type: "Number", value: 9, sort_order: 9 },
    { type: "Number", value: 10, sort_order: 10 }
  ]);
  const [selectedOption, setSelectedOption] = useState({});

  useEffect(() => {
    setQuestion(props.question);

    if (props.response) {
      const val = options.find(e => e.value == props.response);
      if (val) setSelectedOption(val);
    }

    if (props.question.options) {
      setOptions([...props.question.options]);
    }

    if (props.debug) {
      console.log(`Question received: ${JSON.stringify(props.question)}`);
    }
  }, [props]);

  const handleOptionSelect = (obj) => {
    setSelectedOption(obj);
    if (props.updateInParent) {
      const ops = options.filter(e => Number(e.value) <= Number(obj.value));
      props.updateInParent(ops, "options");
    } else if (props?.saveResponse) {
      // const ops = options.filter(e => e.value <= obj.value);
      props.saveResponse(obj.value, "options");
    }
  };

  return (
    <Box sx={{ width: { xs: '100%', sm: 'auto' }, overflowX: 'auto', display: "flex", justifyContent: "center", alignItems: "center", gap: { xs: 1, sm: 1.5, md: 2 } }}>
               {props.showOptionLabel != false && props.showOptionLabel != false  && props?.question?.option_labels?.left && 
                    <Typography
                        sx={{
                            // position: 'absolute',
                            left: { xs: '-60px', sm: '-30px', md: '-35px' },
                            top: '0',
                            fontSize: { xs: '12px', sm: '13px', md: '15px' },
                   
                        }}
                    >
                        {props.question.option_labels.left}
                    </Typography>
                }

      <Stack direction="row" spacing={1} sx={{ width: '100%', justifyContent: 'start', alignItems: 'center', flexWrap: "wrap", gap: 1 }} paddingY={2}>
        {options?.map((obj, i) => (
          <Button
            key={obj.sort_order}
            disabled={props.disable}
            variant="outlined"
            color="primary"
            onClick={() => handleOptionSelect(obj)}
            sx={{
              minWidth: '40px',
              height: '40px',
              borderRadius: '50%',
              border: selectedOption?.value === obj.value ? `2px solid ${getButtonColor(i, options.length)}` : '1px solid rgba(0, 0, 0, 0.23)',
              backgroundColor: selectedOption?.value === obj.value ? `${getButtonColor(i, options.length)}25` : 'transparent',
              '&:hover': {
                backgroundColor: `${getButtonColor(i, options.length)}10`,
                borderColor: getButtonColor(i, options.length),
              }
            }}
          >
            {obj.value}
          </Button>
        ))}
      </Stack>
      {props.showOptionLabel != false && props.showOptionLabel != false && props?.question?.option_labels?.right && 
                    <Typography
                        sx={{
                            // position: 'absolute',
                            right: { xs: '-40px', sm: '-35px', md: '-40px' },
                            top: '0',
                            fontSize: { xs: '12px', sm: '13px', md: '15px' },
                        }}
                    >
                        {props.question.option_labels.right}
                    </Typography>
                }
    </Box>
  );
};

export default Scale;

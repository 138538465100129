import React, { Fragment, useEffect, useState } from 'react';
import { faArrowRight, faChevronLeft, faGripLines, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import QuestionComponent from './QuestionComponent';
import QuestionTypes from './QuestionTypes/questionTypes';
import { setCreateSurvey, createNewQuestion, getSurvey } from '../../../../../actions/surveyActions';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import css from './QuestionRenderer.module.css';
import ItemAddButton from '../../../../../components/UI/buttons/itemAddButton';
import PrimaryButton from '../../../../../components/UI/buttons/primaryButton';
import Cross from '../../../../../components/UI/buttons/crossButton';
import { add_auth_header, deepCopy } from "../../../../../components/Utility/Utility";
import { AddCircle } from '@material-ui/icons';
import { LoadingButton } from '@mui/lab';
import { commonColors } from '../../../../../components/Utility/Constants';

const NewQuestion = props => {

    const quest_template = {
        question_txt: '',
        type: "Categorical",
        display:'',
        options:null,
        conditional_question_available: false,
        conditional_questions: []
    }

    const [surveyId, setSurveyId] = useState(null);
    const [sectionId, setSectionId] = useState(null);
    const [question,setQuestion] = useState(quest_template);
    const [questionIndex, setQuestionIndex] = useState(null);

    const [questions, setQuestions] = useState([]);
    const [addNewQues, setAddNewQues] = useState(false);
    const [showErrMsg,setShowErrMsg] = useState(false);
    const [errMsg,setErrMsg] = useState("All fields are not present");
    
    useEffect(() => {
        setSurveyId(props.surveyId);
        setSectionId(props.sectionId);
        setQuestions(props.questions);
        setQuestionIndex(props.index);
    }, [props.surveyId, props.sectionId, props.questions, props.index])

    const clearDataHandler = () => {
        setQuestion({
            question_txt: '',
            type: "Categorical",
            display:'',
            options:[],
            conditional_question_available: false,
            conditional_questions: null
        });
        setAddNewQues(false);
        setShowErrMsg(false);
    }

    

    return(
        <Fragment>
                {addNewQues && <div style={{
                    marginTop:"36px"
                }}>
                     <QuestionComponent 
                            debug={false}
                            key={questionIndex}
                            index={questionIndex}
                            surveyId = {surveyId}
                            sectionId = {sectionId}
                            question = {question}
                            cancelAddingNewQuestion = {() => {setAddNewQues(false)}}
                            handleQuestionDeleteFromSection={props.handleQuestionDeleteFromSection}
                            setQuestions={setQuestions}
                            updateMainQuestionData={props.updateMainQuestionData}
                    />
                    
                    {showErrMsg && <p style = {{color:"red"}}>{errMsg}</p>}
                </div>}
            {!addNewQues && <div className = {css.EditTemplateQuestion} style={{cursor:"pointer"}}>
                {/* <ItemAddButton buttonTitle="Add Question" clickHandler={() => setAddNewQues(true)} width={160} height={35} fontSize={18}/>
                <div className = {css.ActionIconCont}>
                </div> */}

<LoadingButton
        loading={0}
        loadingPosition="end"
        startIcon={<AddCircle/>}
        variant="contained"
        sx={{
            // backgroundColor: "gray",
            margin:"12px 0px",
            "&:hover": {
              backgroundColor: "darkgray",
            },
            width:"200px",
                 padding : "12px 16px"
          }}
              style={{
                    background: commonColors.soft_gray,
                  }}
          onClick={(e) => {
            e?.stopPropagation()
              setAddNewQues(true)
            }
        }
      >
       <span>
       Add Question
       </span>
      </LoadingButton>
            </div>}
        </Fragment>
    );
}

const mapStateToProps = state => {
    return{
        createSurvey: state.gen.createSurvey
    }
}

const mapDispatchToProps = dispatch => {
    return{
        add_auth_header: bindActionCreators(add_auth_header, dispatch),
        createNewQuestion: bindActionCreators(createNewQuestion, dispatch),
        };
    };

export default connect(mapStateToProps,mapDispatchToProps)(NewQuestion);
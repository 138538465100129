import React, { useEffect, useRef } from 'react'
import {
  Box,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { changeLoadingStatePlaceAndEvent, delete_Place_Events, fetchPlaceAndEventData } from '../../commons/components/Settings/actions/settingsActions';
import { colorWithType } from '../../components/Utility/Constants';
import CommonSingleRow from '../../commons/components/Settings/components/CommonSingleRow';
import Heading from './Heading';
import CommonTableUI from '../../commons/components/Settings/components/CommonTableUI';
import { CustomRating } from './CustomRating';

const columns = [
  { name: "Name",orderBy:"name"},
  { name: "Category",orderBy:"category"},
  // { name: "Distance",orderBy:"distance"},
  { name: "Rating",orderBy:"rating"},
  { name: "Status",orderBy:"status"},
  {name:"Action"}
];

const dimension = {
  created_at: { maxWidth: "150px", minWidth: "150px" },
  time: { maxWidth: "140px", minWidth: "140px" },
  name: { maxWidth: "170px", minWidth: "170px" },
  venue: { maxWidth: "120px", minWidth: "120px" },
  category: { maxWidth: "150px", minWidth: "150px" },
  rating: { maxWidth: "120px", minWidth: "120px" },
  status: { maxWidth: "100px", minWidth: "100px" }
};



 const filterRoute = `/trip-planner`
const PlacesTab = () => {
    const APITrigger = useRef(null);
    const navObj = useSelector((state) => state.nav.selectedNav || {});

  const selectedFilters = useSelector(
    (state) => state.filter.selectedFilters[`${filterRoute}`] || []
  );

  const selectedHotel = useSelector(
          (state) => state.filter.selectedHotel || {}
        );
        const placeDataSelector = state => state.settings?.PlaceAndEvent?.PLACE?.data || []
        const placeCount = useSelector(state => state.settings?.PlaceAndEvent?.PLACE?.count || 0)
        const placepage =  useSelector(state => state.settings?.PlaceAndEvent?.PLACE?.page || 0)
        const placeLoadingSelector = state => state.settings?.PlaceAndEvent?.PLACE?.loading
        const placeErrorSelector = state => state.settings?.PlaceAndEvent?.PLACE?.error
        const dispatch = useDispatch()
        const navigate = useHistory()

              useEffect(() => {
                if (APITrigger.current) clearTimeout(APITrigger.current);
                if (Object.keys(selectedHotel).length > 0 
                &&   selectedFilters.length > 0
              ) {
                  APITrigger.current = setTimeout(() => {
        
            dispatch(changeLoadingStatePlaceAndEvent(true,"PLACE"))
          dispatch(fetchPlaceAndEventData(0,changeLoadingStatePlaceAndEvent,false,null,null,{
            filterkey:`${filterRoute}`,
            entityType:"PLACE"
          }));
                  }, 0);
                }
        
              }, [selectedFilters]);

              const fetchPlaces = (newPage)=>{
                dispatch(changeLoadingStatePlaceAndEvent(true,"PLACE"))
                dispatch(fetchPlaceAndEventData(newPage,changeLoadingStatePlaceAndEvent,false,null,null,{
                  filterkey:`${filterRoute}`,
                  entityType:"PLACE"
                }));
              }

                    const handleEdit = (data)=>{
                      navigate.push(`/trip-planner/manage?id=${data.id}&entity=EVENT`) 
                    }
        
                          const hadleDelete = (data)=>{
                            dispatch(delete_Place_Events(data.id,{
                              entityType:data.type,
                            }))
                          }
                          const menuItems = [
                            { label: 'Edit', onClick: handleEdit },
                            { label: 'Delete', onClick: hadleDelete },
                            
                          ]

                          const RowComponent = ({row={}}) => {
                            let payload = {
                              name:row.name,
                              category:row.category?.display_name,
                              // distance:row.distance || "--",
                              rating:     CustomRating(row.rating) ,
                              status: row.status ? <Box sx={{
                                display:'inline-block',
                                borderRadius:"16px",
                                background:colorWithType[row.status]?.bg || colorWithType["new"]?.bg,
                                color:colorWithType[row.status]?.tx || colorWithType["new"]?.tx,
                                padding:"4px 8px"
                               }}>
                            <Typography
                            sx={{
                              fontSize:"12px",
                              lineHeight:"18px",
                              letterSpacing:"0.4px"
                            }}
                            >
                              {row.status}
                            </Typography>
                               </Box>:"",
                          }
              
                          const css = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textTransform: 'capitalize'}
                          return(
                            <>
                        <CommonSingleRow
                          key={`${row?.id}`}
                          payload={ payload}
                          showMenu={true}
                          menuItems={menuItems}
                          id={row.id}
                          fullData = {row}
                          commonCss={css}
                          // dimension={dimension}
                          // imageList ={row?.images || []}
                        />
                              </>
                            );
                          };
    
                          return <Box 
                          sx={{
                            display:"flex",
                            flexDirection:"column",
                            justifyContent:'start',
                            alignItems:"start",
                            gap:"12px",
                            width:'100%'
                          }}
                          >
                       <Heading title="Places" count={9} />
                      <CommonTableUI
                      columns={columns}
                      fetchData={fetchPlaces}
                      dataSelector={placeDataSelector}
                      loadingSelector={placeLoadingSelector}
                      errorSelector={placeErrorSelector}
                      isEditPermission={true}
                      RowComponent = {RowComponent}
                      minRow={20}
                      count={placeCount}
                      page={placepage}
                      initialFetch={false}
                      title={"Places"}
                      heading={false}
                      maxHeight={"1096px"}
                      serverSidePagination={true}
                      />
                      
                       </Box>
}

export default PlacesTab
import React, { useState, useEffect } from 'react';
import { Controller } from "react-hook-form";
import { 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  Checkbox, 
  ListItemText, 
  Input,
  styled 
} from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== 'minWidth' && prop !== 'maxWidth'
})(({ theme, minWidth = 220, maxWidth = 300 }) => ({
  margin: theme.spacing(1),
  minWidth: `${minWidth}px`,
  maxWidth: `${maxWidth}px`,
  transform: 'translateY(-40%)'
}));

const CheckboxDropdown = ({
  label,
  control,
  errors,
  allObjects = [],
  selectedObjects = [],
  onChange,
  debug = false,
  displayLabel,
  minWidth = 220,
  maxWidth = 300,
  ...props
}) => {
  const [localAllObjects, setLocalAllObjects] = useState(allObjects);
  const [localSelectedObjects, setLocalSelectedObjects] = useState(selectedObjects);

  useEffect(() => {
    setLocalAllObjects(allObjects);
    setLocalSelectedObjects(selectedObjects);

    if (debug) {
      console.log(`useEffect: selectedObjects: ${JSON.stringify(selectedObjects)}. allObjects: ${JSON.stringify(allObjects)}`);
    }
  }, [allObjects, selectedObjects, debug]);

  const handleChange = (e) => {
    const selectedObjIds = e.target.value;

    if (debug)
      console.log(`selected_obj_ids: ${JSON.stringify(selectedObjIds)}`);
    
    const newSelectedObjects = localAllObjects.filter(obj => selectedObjIds.includes(obj.id));

    if (onChange) {
      if (debug)
        console.log(`calling onChange: ${JSON.stringify(newSelectedObjects)}`);
      onChange(newSelectedObjects);
    }

    setLocalSelectedObjects(newSelectedObjects);
  };

  return (
    <div>
      <StyledFormControl 
        minWidth={minWidth} 
        maxWidth={maxWidth}
        {...props}
      >
        {localSelectedObjects.length === 0 && (
          <InputLabel id="multiple-checkbox-label" disabled>
            {displayLabel || label}
          </InputLabel>
        )}
        <Controller
          control={control}
          name={label}
          defaultValue={null}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              multiple
              onChange={handleChange}
              value={localSelectedObjects.map(obj => obj.id)}
              renderValue={(selected) => 
                localAllObjects
                  .filter(obj => selected.includes(obj.id))
                  .map(selObj => selObj.label)
                  .join(", ")
              }
              labelId="multiple-checkbox-label"
              id="multiple-checkbox"
              input={<Input />}
              MenuProps={MenuProps}
            >
              {localAllObjects.map((object) => (
                <MenuItem key={object.id} value={object.id}>
                  <Checkbox
                    checked={localSelectedObjects.some(obj => obj.id === object.id)}
                    checkedIcon={<CheckBoxOutlinedIcon style={{color: "#7ea63f"}}/>}
                  />
                  <ListItemText primary={object.label} />
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors?.[label]?.type === 'required' && (
          <span style={{color:'red', fontSize:'0.8em'}}>
            At least one selection is required
          </span>
        )}
      </StyledFormControl>
    </div>
  );
};

export default CheckboxDropdown;
import React, { useState, Fragment, useEffect } from 'react';
import Slider from './QuestionType/Slider';
import Checkbox from './QuestionType/Checkbox';
import RadioBtn from './QuestionType/RadioBtn';
import TripType from './QuestionType/TripType';
import Button from './QuestionType/Button';
import TextArea from './QuestionType/TextArea';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Typography, Box } from '@mui/material';
import Scale from '../../../ManageSurvey/components/QuestionTypes/Scale';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const QuestionRenderer = props => {
    const [question, setQuestion] = useState({});
    const [response, setResponse] = useState({});
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const preview = queryParams.get("preview") || null;
    const viewResp = queryParams.get("viewResp") || null;

    useEffect(() => {
        setQuestion(props.question || {});
        setResponse(props.response || {});
    }, [props.question, props.response])

    const saveResponse = (resp_val) => {
        let _resp = {'answer_txt': resp_val, 'question': question.id };
        props.saveResponse(_resp);
    }

    const inputTypeHandler = (type) => {
        const checkForDisable = ()=>{
            if(response?.enabled === false || viewResp)
                return true
            return false
        }
        const disabled = checkForDisable()
        switch(type){
            case 'Slider':
                return <Box sx={{ width: { xs: '100%', sm: '90%', md: '70%' } }}>
                    <Slider 
                        saveResponse={saveResponse} 
                        response={response.answer_txt} 
                        question={question}
                        debug={true}
                        disable={ disabled}
                    />
                </Box>

            case 'Checkbox':
                return <Box sx={{ width: '100%' }}>
                    <Checkbox 
                        saveResponse={saveResponse} 
                        response={response.answer_txt} 
                        question={question}
                        disable={ disabled}
                    />
                </Box>

            case 'TripType':
                return <Box sx={{ width: '100%' }}>
                    <TripType 
                        saveResponse={saveResponse} 
                        response={response.answer_txt} 
                        question={question}
                        disable={ disabled}
                    />
                </Box>

            case 'Radio Button':
                return <Box sx={{ width: '100%' }}>
                    <RadioBtn 
                        saveResponse={saveResponse} 
                        response={response.answer_txt} 
                        question={question}
                        debug={false}
                        disable={ disabled}
                    />
                </Box>

            case 'Buttons':
                return <Box sx={{ 
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: { xs: 1, sm: 2 }
                }}>
                    <Button 
                        saveResponse={saveResponse} 
                        response={response.answer_txt} 
                        question={question}
                        disable={ disabled}
                    />
                </Box>

            case 'Text Box':
                return <Box sx={{ width: { xs: '100%', sm: '90%', md: '90%' } }}>
                    <TextArea 
                        saveResponse={saveResponse} 
                        response={response.answer_txt} 
                        question={question}
                        disable={ disabled}
                    />
                </Box>
            
            case 'Scale':                
                return <Box sx={{ 
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: { xs: 1, sm: 2 }
                }}>
                    <Scale 
                        saveResponse={saveResponse} 
                        response={response?.answer_txt} 
                        question={question}
                        disable={ disabled}
                        // debug={true}
                    />
                </Box>
        }
    } 

    return(
        <Fragment>
            <Card
                sx={{
                    width: '100%',
                    margin: { xs: '16px 0', sm: '20px 0' },
                    minHeight: '170px',
                    borderRadius: '12px',
                    padding: { xs: '4% 3%', sm: '2% 5%' },
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f9f9',
                    position: 'relative'
                }}
            >
                <CardContent sx={{ 
                    position: 'relative', 
                    padding: { xs: '12px', sm: '16px' },
                    '&:last-child': { paddingBottom: { xs: '16px', sm: '24px' } }
                }}>
                    {/* Question Index */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: { xs: '-12px', sm: '-16px' },
                            left: { xs: '-12px', sm: '-16px' },
                            width: { xs: '40px', sm: '51px' },
                            height: { xs: '40px', sm: '51px' },
                            backgroundColor: '#E4E6E7',
                            borderRadius: '12px 0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: { xs: '20px', sm: '24px' },
                            fontWeight: 600,
                            color: 'black'
                        }}
                    >
                        {props.index}
                    </Box>

                    {/* Question Text */}
                    <Typography
                        variant="h6"
                        sx={{
                            marginTop: { xs: '8px', sm: '16px' },
                            marginLeft: { xs: '32px', sm: '40px' },
                            fontWeight: 500,
                            fontSize: { xs: '1.1rem', sm: '1.2rem' },
                            color: '#333',
                            textAlign: 'left',
                        }}
                    >
                        {question.question_txt}
                    </Typography>

                    {/* Input Type Container */}
                    <Box
                        sx={{
                            marginTop: { xs: '16px', sm: '24px' },
                            // marginLeft: { xs: '', sm: '40px' },
                            textAlign: 'left',
                        }}
                    >
                        {inputTypeHandler(question.display)}
                    </Box>
                </CardContent>
            </Card>
        </Fragment>
    );
}

export default QuestionRenderer;
import { Box, Typography } from '@mui/material'
import React from 'react'

const ImageIndex = ({index=1,addCss={}}) => {
  return (
   <Box
   sx={{
    display: 'flex',
    flexDirection: 'column',
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    gap:0.5,
   backgroundColor:"rgba(255, 255, 255, 0.8)",
    borderRadius: "4px",
    ...addCss
   }}
   >
    <Typography variant="body1"
    fontWeight={500}
    color={"black"}
    sx={{
        // marginTop:"-1px",
        fontSize: "14px",
        letterSpacing:"-0.5px",
        lineHeight: "16px",
    }}
    >
        {index}
    </Typography>
   </Box>
  )
}

export default ImageIndex

import React, { useEffect, useState } from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown, faLongArrowAltUp, faSave } from '@fortawesome/free-solid-svg-icons';
import QuestionRenderer from './QuestionTypes/QuestionRenderer';
import ProgressSlider from './ProgressSlider';
import {saveSectionResponses} from '../../../../../actions/surveyActions';
import {add_auth_header, get_query_param} from '../../../../../components/Utility/Utility';
import css from '../UserSurvey.module.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const SectionRenderer = props => {

    const [surveyToken, setSurveyToken] = useState(null);
    const [section, setSection] = useState({});
    const [sectionIndex, setSectionIndex] = useState();
    const [questions, setQuestions] = useState([])
    const [responses, setResponses] = useState(null)
    const [questionCount, setQuestionCount] = useState(null);
    const [currQuestion, setCurrQuestion] = useState(null);
    const [currQuestionIdx, setCurrQuestionIdx] = useState(null);
    const [isPrev, setIsPrev] = useState(false);
    const [isNext, setIsNext] = useState(false);
    const [save, setSave] = useState(false);
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const camp_code = queryParams.get("camp_code") || null

    const saveResponse = (response) => {
        let _responses = {...responses}

        let _answer = _responses.answers.find(a => a.question == response.question);

        console.log("survey answer",responses)

        if (_answer) {Object.assign(_answer, response);}
        else _responses.answers.push(response);
        
        _responses = {...responses, section:props.section.id }
        console.log("survey answer 2",responses)
        setResponses(_responses);
        props.setResponses(_responses)
        console.log(`Responses: ${JSON.stringify(_responses)}`); 
    }

    const submitSectionResponse = () => {
        props.saveSectionResponses(props.add_auth_header({}), surveyToken, responses, (status) => {
            if (status) props.sectionHandler(null, 'next');
            if (props.updateResponsesForSection) props.updateResponsesForSection(responses);
        },camp_code);
    }

    const getResponse = (quest_id) => {
        if (!responses) return null;
        return responses.answers.find( a => a.question == quest_id)
    };

    useEffect(() => {
        if(props.section){
            setSection(props.section)
            setQuestions(props.section.questions);
            setQuestionCount(props.section.questions_count);
            if(props.section.questions_count > 0 ) setCurrQuestionIdx(0);
            // if (!responses && !props.responses) setResponses({'section': props.section.id, 'answers': []});
            setResponses({'section': props.section.id, 'answers': []});
        }
        setSectionIndex(props.index);
        setSurveyToken(props.surveyToken);
        if (props.responses) {setResponses(props.responses)};

        console.log(`sr responses: ${JSON.stringify(props.responses)}`);

    }, [props.section, props.index, props.surveyToken, props.responses])

    useEffect(() => {
        if(currQuestionIdx >= 0)
            setCurrQuestion(props.section.questions[currQuestionIdx]);

        if (currQuestionIdx > 0)
            setIsPrev(true)
        else
            setIsPrev(false)
        
        if (currQuestionIdx < questionCount-1) {setIsNext(true); setSave(false)}
        else {setIsNext(false); setSave(true)}

    }, [currQuestionIdx, questions])
    
    const questionHandler = (e,type) => {
        switch(type){
            
            case 'next':
                if (currQuestionIdx < questionCount-1)
                    setCurrQuestionIdx( prev => prev+1);
                break;

            case 'back':
                if (currQuestionIdx > 0)
                    setCurrQuestionIdx( prev => prev-1);
                break; 
            default:
                console.log('Invalid type');
        }
    }

    return(
        <React.Fragment>
            {/* <div>
                Section {sectionIndex}: {section.name}
            </div> */}{console.log("responses",responses)}

            {props.section.questions.map((question, i) => {
                console.log("question",question)
                return (<QuestionRenderer
                    question={question}
                    index={i + 1}
                    response={getResponse(question.id)}
                    saveResponse={saveResponse}
                />)
            })}

            {/* {currQuestion && <QuestionRenderer
                                    question={currQuestion}
                                    response={getResponse(currQuestion.id)}
                                    saveResponse={saveResponse}
                                    />} */}

            {/* <div className = {css.BackNextContainer}>
                {isPrev && <p onClick = {(e) => questionHandler(e,'back')}><FontAwesomeIcon icon = {faLongArrowAltUp}/> back</p>}
                {isNext && <p onClick = {(e) => questionHandler(e,'next')}>next <FontAwesomeIcon icon = {faLongArrowAltDown}/></p>}
                {save && <p onClick = {submitSectionResponse}>Save <FontAwesomeIcon icon = {faSave}/></p>}
            </div> */}
            
        </React.Fragment>
    );
}

const mapDispatchToProps = dispatch => {
    return{
        add_auth_header: bindActionCreators(add_auth_header, dispatch),
        saveSectionResponses: bindActionCreators(saveSectionResponses, dispatch)
        };
    };

export default connect(null,mapDispatchToProps)(SectionRenderer);